<template>
  <div class="theme-slots">
    <div class="best-players-title">{{ t('slots-themes') }}</div>
    <div class="theme-slots-slider">
      <swiper
        :slides-per-view="1"
        :modules="modules"
        :navigation="{ nextEl: '.theme-slots-next', prevEl: '.theme-slots-prev' }"
        :breakpoints="{
          '350': {
            slidesPerView: 2,
          },
          '500': {
            slidesPerView: 3,
          },
          '750': {
            slidesPerView: 4,
          },
          '900': {
            slidesPerView: 5,
          },
          '1070': {
            slidesPerView: 6,
          },
          '1350': {
            slidesPerView: 7,
          },
        }"
      >
        <swiper-slide class="swiper-slide theme-slots-item" v-for="topic in topics" :key="topic.id">
          <router-link
            :to="{
              name: 'category-topic',
              params: { topic: topic.slug },
            }"
          >
            <span class="theme-slots-item-image">
              <img :src="topic.icon" :alt="topic.title" />
            </span>
            <span class="theme-slots-item-title"
              >{{ topic.title }} <span>{{ topic?.gamesCount }}</span></span
            >
          </router-link>
        </swiper-slide>
      </swiper>

      <div class="theme-slots-prev slider-arrow" />
      <div class="theme-slots-next slider-arrow slider-arrow-right" />
    </div>
    <a href="/topics" class="all">Все</a>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation } from 'swiper/modules'
import { mapGetters } from 'vuex'

export default {
  name: 'SlotTopics',
  components: {
    Swiper,
    SwiperSlide,
  },
  computed: {
    ...mapGetters(['topics']),
  },
  setup() {
    const { t } = useI18n()

    return {
      modules: [Navigation],
      t,
    }
  },
}
</script>
<style scoped></style>
