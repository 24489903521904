<template>
  <div class="profile-block">
    <div class="form-select-block">
      <div class="form-select-block-title">Выберите дату:</div>
      <div class="form-select-block-select">
        <select v-if="days" @change="getData($event.target.value)">
          <option v-for="(day, index) in days" :value="index" :key="index">
            {{ format(day, 'dd MMMM', { locale: ru }) }}
          </option>
        </select>
      </div>
    </div>

    <div class="history-table-wrap">
      <table class="history-table" v-if="!this.isLoading && this.list">
        <thead>
          <tr>
            <td>Дата</td>
            <td>Игра</td>
            <td>Ставка</td>
            <td>Выигрыш</td>
            <!--            <td>Баланс</td>-->
          </tr>
        </thead>
        <tbody>
          <tr v-for="(elem, index) in list" :key="index">
            <td>{{ format(new Date(elem?.data), 'dd.MM.yyyy') }}</td>
            <td>
              <div class="history-table-name">
                <img
                  :src="this.gameBySlug(elem?.game_slug).icon"
                  :alt="this.gameBySlug(elem?.game_slug).title"
                /><span>{{ this.gameBySlug(elem?.game_slug).title }}</span>
              </div>
            </td>
            <td>{{ elem?.bet }} ₽</td>
            <td>
              <div class="tournaments-table-win-line">{{ elem?.win }} ₽</div>
            </td>
            <!--            <td>- ₽</td>-->
          </tr>
        </tbody>
      </table>
      <div v-else-if="this.isLoading && !this.list">Загрузка</div>
      <div v-else>Данных нет</div>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { format, subDays } from 'date-fns'
import { ru } from 'date-fns/esm/locale'
import { GET_BETS_HISTORY } from '@/store/actions.type'
import { mapGetters } from 'vuex'
import { toast } from 'vue3-toastify'

export default {
  name: 'History',
  setup() {
    const { t, n } = useI18n()
    return { t, n }
  },
  data() {
    return {
      page: 0,
      tab: 0,
      days: null,
      list: null,
      isLoading: true,
      format,
      ru,
    }
  },
  computed: {
    ...mapGetters(['gameBySlug']),
  },
  mounted() {
    let array = []

    for (let i = 0; i <= 13; i++) {
      array.push(subDays(new Date(), i))
    }

    this.days = array

    this.getData(0)
  },
  methods: {
    getData(value) {
      this.$store
        .dispatch(GET_BETS_HISTORY, { time_zone_shift: new Date().getTimezoneOffset() / 60, day_shift: Number(value) })
        .then(({ data }) => {
          this.list = data
          this.isLoading = false
        })
        .catch(res => {
          toast.error(res?.data?.err)
          this.isLoading = false
        })
    },
  },
}
</script>

<style scoped></style>
