<template>
  <div class="best-players" v-if="winnersWeek.length">
    <div class="best-players-title">Сейчас выигрывают</div>
    <div class="best-players-slider">
      <swiper
        :slides-per-view="1"
        :loop="true"
        :autoplay="{
          delay: 2000,
          disableOnInteraction: false,
          reverseDirection: true,
        }"
        :modules="modules"
        :navigation="{ nextEl: '.bpsi-next', prevEl: '.bpsi-prev' }"
        :breakpoints="{
          '320': {
            slidesPerView: 1,
          },
          '480': {
            slidesPerView: 2,
          },
          '800': {
            slidesPerView: 3,
          },
          '1000': {
            slidesPerView: 4,
          },
          '1350': {
            slidesPerView: 5,
          },
        }"
      >
        <swiper-slide v-for="winner in winnersWeek" :key="winner.id + '-' + isAuthenticated">
          <div class="best-players-slider-item" :set="(winGame = gameBySlug(winner.gameSlug))">
            <div class="bpsi">
              <div class="bpsi-image">
                <img :src="winGame?.icon" width="65" height="65" :title="winGame?.title" />
              </div>
              <div class="bpsi-c">
                <div class="bpsi-name">
                  {{ winner.username }}
                  <br />выиграл <span>{{ n(winner.win) }}</span>
                </div>
                <div class="bpsi-win">
                  <span>в {{ winGame?.title }}</span>
                </div>
              </div>
              <div class="bpsi-c-hover">
                <router-link
                  v-if="isAuthenticated"
                  :to="{ name: 'game', params: { game: winGame.slug } }"
                  class="sgio-link"
                  >Играть</router-link
                >
                <router-link v-else :to="{ name: 'demo', params: { game: winGame.slug } }" class="sgio-link"
                  >Играть</router-link
                >
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>

      <div class="bpsi-prev slider-arrow" />
      <div class="bpsi-next slider-arrow slider-arrow-right" />
    </div>
  </div>
</template>

<script>
import { FETCH_WINNERS } from '@/store/actions.type'
import { mapGetters } from 'vuex'
import { useI18n } from 'vue-i18n'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Autoplay, Navigation } from 'swiper/modules'

export default {
  name: 'Winners',
  setup() {
    const { t, n } = useI18n()

    return { t, n, modules: [Autoplay, Navigation] }
  },
  computed: {
    ...mapGetters(['winnersWeek', 'gameBySlug', 'isAuthenticated']),
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  created() {
    this.$store.dispatch(FETCH_WINNERS)
  },
}
</script>

<style scoped></style>
