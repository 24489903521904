<template>
  <div class="profile-data-col">
    <div class="profile-data-col-title">Личные данные</div>
    <VForm class="profile-data-col-block" autocomplete="off" @submit="this.saveDate" :validation-schema="this.schema">
      <div class="profile-data-line">
        <div class="profile-data-line-title">Логин:</div>
        <VField
          type="text"
          name="name"
          class="input-standart"
          placeholder="Логин"
          mode="aggressive"
          v-model="username"
        />
        <ErrorMessage name="name"> Поля обязательное </ErrorMessage>
      </div>
      <div class="profile-data-line unconfirmed" v-if="!confirmed">
        <VerificationPhone :pre-defined-phone="currentUser?.phone" />
      </div>
      <div class="profile-data-line good" v-else>
        <div class="profile-data-line-title">Номер телефона:</div>
        <VField
          type="text"
          name="phone"
          class="input-standart"
          placeholder="Введите телефон"
          mode="aggressive"
          v-model="phone"
          :disabled="this.phoneDisable"
        />
      </div>
      <div class="profile-data-line" v-if="currentUser.email">
        <div class="profile-data-line-title">E-mail:</div>
        <VField
          type="text"
          name="email"
          class="input-standart"
          placeholder="Введите e-mail"
          mode="aggressive"
          :value="currentUser.email"
          disabled
        />
        <!--        <ErrorMessage name="email" />-->
        <!--        <button type="submit" class="orange-btn" :disabled="this.isLoading">Подтвердить почту</button>-->
      </div>
      <!--<div class="profile-data-line">
        <div class="profile-data-line-title">Дата рождения:</div>
        <VField
          type="date"
          name="date"
          class="input-standart"
          placeholder="22.10.1984"
          mode="aggressive"
          v-model="date"
        />
        <ErrorMessage name="date" />
      </div>-->

      <!--      <button class="orange-btn" type="submit" :disabled="this.isLoading">Сохранить данные</button>-->
    </VForm>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Field, Form, ErrorMessage } from 'vee-validate'
import * as yup from 'yup'
import { CHANGE_PROFILE } from '@/store/actions.type'
import { toast } from 'vue3-toastify'
import VerificationPhone from '@/components/cashdesk/Verification/Phone.vue'

export default {
  name: 'Profile',
  data() {
    return {
      phone: null,
      username: null,
      date: null,
      phoneDisable: false,
      schema: yup.object().shape({
        name: yup.string().min(2).required('Поле обязательное'),
        phone: yup.string().min(5).required('Поле обязательное'),
        date: yup.string().min(5).required('Поле обязательное'),
      }),
    }
  },
  components: {
    VerificationPhone,
    VForm: Form,
    VField: Field,
    ErrorMessage,
  },
  computed: {
    ...mapGetters(['currentUser']),
    confirmed() {
      return !!this.currentUser?.confirmed
    },
  },
  created() {
    this.setDate({
      username: this.currentUser?.username,
      phone: this.currentUser?.phone,
      profile: {
        birthday: this.currentUser?.profile?.birthday,
      },
    })

    const unwatch = this.$watch('currentUser', value => {
      this.setDate(value)
      unwatch()
    })
  },

  methods: {
    setDate(value) {
      if (!this.username) {
        this.username = value.username
      }

      if (!this.phone) {
        this.phone = value.phone
        this.phoneDisable = true
      }

      if (value.profile?.birthday) {
        const date = new Date(value.profile?.birthday)
        const dateToISO = date.toISOString().substr(0, 10)

        this.date = dateToISO
      }
    },
    saveDate(props) {
      this.isLoading = true

      this.$store
        .dispatch(CHANGE_PROFILE, { birthday: props.date, phone: props.phone, first_name: props.name, last_name: '' })
        .then(({ data }) => {
          toast.success('Данные изменены')
          this.isLoading = false
        })
        .catch(res => {
          toast.error(res?.data?.err)
          this.isLoading = false
        })
    },
  },
}
</script>

<style scoped>
.orange-btn {
  border: none;
  cursor: pointer;
}
</style>
