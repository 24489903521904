<template>
  <div class="footer-slider-wrap">
    <div class="footer-slider-w">
      <router-link :to="{ name: 'news' }" class="footer-slider-title">Новости</router-link>

      <div class="footer-slider">
        <swiper
          :slides-per-view="1"
          :modules="modules"
          :navigation="{ nextEl: '.footer-slider-next', prevEl: '.footer-slider-prev' }"
        >
          <swiper-slide v-for="newsItm in news" :key="newsItm.slug">
            <div class="footer-slider-item">
              <router-link :to="{ name: 'news', hash: '#' + newsItm.slug }" class="footer-slider-item-title">{{
                newsItm.title
              }}</router-link>
              <div class="footer-slider-item-text" v-html="newsItm.short_text" />
            </div>
          </swiper-slide>
        </swiper>
      </div>

      <div class="footer-slider-nav">
        <router-link :to="{ name: 'news' }">Все</router-link>
        <div class="footer-slider-prev slider-arrow" />
        <div class="footer-slider-next slider-arrow slider-arrow-right" />
      </div>
    </div>
  </div>

  <div class="footer footer-fh">
    <div class="footer-w">
      <div class="footer-brands">
        <swiper
          :slides-per-view="1"
          :modules="modules"
          :navigation="{ nextEl: '.footer-next', prevEl: '.footer-prev' }"
          :autoplay="{ delay: 2000 }"
          :breakpoints="{
            '500': {
              slidesPerView: 2,
            },
            '700': {
              slidesPerView: 3,
            },
            '950': {
              slidesPerView: 5,
            },
            '1180': {
              slidesPerView: 7,
            },
            '1400': {
              slidesPerView: 7,
            },
          }"
        >
          <swiper-slide
            ><div><img src="/images/providers/igrosoft.svg" alt="igrosoft" /></div
          ></swiper-slide>
          <swiper-slide
            ><div><img src="/images/providers/netent.svg" alt="netent" /></div
          ></swiper-slide>
          <swiper-slide
            ><div><img src="/images/providers/ygg.svg" alt="ygg" /></div
          ></swiper-slide>
          <swiper-slide
            ><div><img src="/images/providers/playgo.svg" alt="playgo" /></div
          ></swiper-slide>
          <swiper-slide
            ><div><img src="/images/providers/belatra-white.svg" alt="belatra" /></div
          ></swiper-slide>
          <swiper-slide
            ><div><img src="/images/providers/microgaming.svg" alt="microgaming" /></div
          ></swiper-slide>
          <swiper-slide
            ><div><img src="/images/providers/greentube.svg" alt="greentube" /></div
          ></swiper-slide>
          <swiper-slide
            ><div><img src="/images/providers/megajack.svg" alt="megajack" /></div
          ></swiper-slide>
          <swiper-slide
            ><div><img src="/images/providers/unicum.svg" alt="unicum" /></div
          ></swiper-slide>
          <swiper-slide
            ><div><img src="/images/providers/betinhell.svg" alt="betinhell" /></div
          ></swiper-slide>
          <swiper-slide
            ><div><img src="/images/providers/pragmatic.svg" alt="pragmatic" /></div
          ></swiper-slide>
          <swiper-slide
            ><div><img src="/images/providers/pushgaming.svg" alt="PushGaming" /></div
          ></swiper-slide>
        </swiper>
        <div class="slider-games-footer">
          <div class="slider-footer-prev footer-prev" />
          <div class="slider-footer-next footer-next slider-arrow slider-arrow-right" />
        </div>
      </div>

      <div class="footer-m">
        <div class="footer-m-left">
          <router-link :to="{ name: 'home' }" class="footer-m-logo">
            <img src="/images/mln-logo-white.svg" alt="" />
          </router-link>

          <img src="/images/footer-m-18.svg" class="footer-m-18" alt="" />
        </div>
        <div class="footer-m-right">
          <div class="footer-menu">
            <div class="footer-menu-col">
              <a href="/faq">FAQ</a>
              <router-link :to="{ name: 'contacts' }">
                {{ t('menu.contacts', 'Контакты') }}
              </router-link>
            </div>
            <div class="footer-menu-col">
              <a href="/rules">Правила и условия</a>
              <a href="/rules">Защита информации</a>
            </div>
            <div class="footer-menu-col">
              <a href="/rules">Отвественная игра</a>
              <a href="/bonuses">Бонусная политика</a>
            </div>
            <div class="footer-menu-col">
              <router-link :to="{ name: 'jackpots' }">
                {{ t('menu.jackpots', 'Джекпоты') }}
              </router-link>
              <a href="https://slots.partners/" target="_blank">Партнёрам</a>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-line">
        <div class="footer-line-soc">
          <a href="https://t.me/millionb_club" class="footer-tg" target="_blank"
            ><img src="/images/footer-tg.svg" alt="" /><span>Телеграм канал</span></a
          >
          <a href="https://www.youtube.com/@mlncasino" class="footer-yt" target="_blank"
            ><img src="/images/footer-yt.svg" alt="" /><span>Youtube канал</span></a
          >

          <div class="footer-line-text">© 2012-2024 Все права защищены</div>
          <i class="fclight"></i>
        </div>

        <div class="footer-line-r">
          <div class="flimg">
            <a href="https://slots.partners/" target="_blank"><img src="/images/flimg4.svg" class="img1" alt="" /></a>
            <img src="/images/flimg1.svg" alt="" />
            <img src="/images/flimg2.svg" alt="" />
            <img src="/images/flimg3.svg" alt="" />
          </div>
          <div class="fr18">18+</div>
          <a href="#" class="footer-live-chat" @click.prevent="support">Онлайн чат</a>
          <!--          <div class="footer-lang">-->
          <!--            <div class="footer-lang-main" @click="this.openLang = !this.openLang">-->
          <!--              <div class="footer-lang-item">-->
          <!--                <img :src="`/images/registration-flags/${this.lang === 'RU' ? 'russia' : 'usa'}.svg`" alt="" />-->
          <!--                <span>{{ this.lang }}</span>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--            <div class="footer-lang-hidden" v-if="this.openLang" @click="this.openLang = false">-->
          <!--              <a class="footer-lang-item" @click="switchLocale('ru')">-->
          <!--                <img src="/images/registration-flags/russia.svg" alt="" />-->
          <!--                <span>RU</span>-->
          <!--              </a>-->
          <!--              <a class="footer-lang-item" @click="switchLocale('en')">-->
          <!--                <img src="/images/registration-flags/usa.svg" alt="" />-->
          <!--                <span>EN</span>-->
          <!--              </a>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
      </div>
    </div>
  </div>

  <authenticated-modals v-if="isAuthenticated" />
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Autoplay, Pagination, Navigation } from 'swiper/modules'
import { getDesiredLocale, setLocale } from '@/common/locale'
import { useI18n } from 'vue-i18n'
import { Talkme } from '@/components/talkme'
import { mapGetters } from 'vuex'
import UserLevelImg from '@/components/UserLevelImg.vue'
import { FETCH_NEWS } from '@/store/actions.type'
import AuthenticatedModals from '@/components/modals/AuthenticatedModals.vue'

export default {
  name: 'TheFooter',
  data() {
    return {
      openLang: false,
      lang: 'RU',
    }
  },
  components: {
    AuthenticatedModals,
    UserLevelImg,
    Swiper,
    SwiperSlide,
  },
  setup() {
    const { t, locale } = useI18n()

    return {
      modules: [Autoplay, Pagination, Navigation],
      t,
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'news']),
  },
  created() {
    this.lang = getDesiredLocale()
    this.$store.dispatch(FETCH_NEWS)
  },
  methods: {
    support() {
      Talkme.open()
    },
    //   switchLocale(locale) {
    //     this.lang = locale.toUpperCase()
    //     setLocale(locale)
    //   },
  },
}
</script>

<style scoped>
.footer {
  background: #002a01;
}

.footer-w {
  width: 1404px;
  margin: 0 auto;
  text-align: left;
}

.footer-brands {
  display: flex;
  padding: 35px 50px 25px 50px;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
}
.footer-brands img {
  filter: invert(1);
  opacity: 0.4;
  height: 37px;
  margin: 15px 5px;
}
.footer-brands img:hover {
  opacity: 1;
}
.footer-brands .swiper-slide > div {
  text-align: center;
}
@media (max-width: 1250px) {
  .footer-brands img {
    margin: 0;
  }
}
@media (max-width: 1500px) {
  .footer-w,
  .footer-slider-w {
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
  }
}
</style>
