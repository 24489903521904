<template>
  <div class="mob-menu" @click="this.openMobileNav()" />
  <div class="mob-menu-panel" v-if="this.openMobNav">
    <div class="mob-menu-wrap">
      <div class="mob-menu-wrap-head">
        <div class="mob-menu-close" @click="this.hideMobileNav()" />
        <div class="logo">
          <router-link :to="{ name: 'home' }" />
          <img class="logobg" src="/images/Frame7.svg" alt="" />
          <img class="logobg-mob" src="/images/Frame8.svg" alt="" />
          <img class="greenl" src="/images/green.svg" alt="" />
          <object class="logoobj" type="image/svg+xml" data="/images/mln-logo-ani(4).svg" />
        </div>
      </div>
      <div class="mob-menu-wrap-menu">
        <router-link
          :to="{ name: 'tournaments' }"
          class="top-menu-link top-menu-link-tournaments"
          exact
          active-class="top-menu-link-active"
        >
          {{ t('menu.tournaments', 'Турниры') }}
          <span class="num" v-if="tournamentsActive?.length > 0">{{ tournamentsActive?.length }}</span>
        </router-link>

        <router-link
          :to="{ name: 'levels' }"
          class="top-menu-link top-menu-link-levels"
          exact
          active-class="top-menu-link-active"
        >
          {{ t('menu.levels', 'Уровни') }}
        </router-link>

        <router-link
          :to="{ name: 'promo' }"
          class="top-menu-link top-menu-link-promo"
          exact
          active-class="top-menu-link-active"
        >
          {{ t('menu.promo', 'Промо') }}
        </router-link>

        <router-link
          :to="{ name: 'wins' }"
          class="top-menu-link top-menu-link-wins"
          exact
          active-class="top-menu-link-active"
        >
          {{ t('menu.wins', 'Победители') }}
        </router-link>

        <router-link
          :to="{ name: 'jackpots' }"
          class="top-menu-link top-menu-link-jackpots"
          exact
          active-class="top-menu-link-active"
        >
          {{ t('menu.jackpots', 'Джекпоты') }}
        </router-link>

        <router-link
          :to="{ name: 'contacts' }"
          class="top-menu-link top-menu-link-contacts"
          exact
          active-class="top-menu-link-active"
        >
          {{ t('menu.contacts', 'Контакты') }}
        </router-link>
      </div>
      <div class="mob-menu-wrap-bottom">
        <router-link :to="{ name: 'favourites' }" class="mob-fav-games" v-if="isAuthenticated"
          >Любимые игры</router-link
        >
        <a href="#" class="mob-online-chat contact-block-chat-btn" @click.prevent="support"><span>Онлайн чат</span></a>
      </div>
    </div>
  </div>

  <ul :class="`top-menu ${this.openDropdown ? 'top-menu2' : ''}`">
    <li>
      <router-link
        :to="{ name: 'tournaments' }"
        class="top-menu-link top-menu-link-tournaments"
        exact
        active-class="top-menu-link-active"
      >
        {{ t('menu.tournaments', 'Турниры') }}
        <span class="num" v-if="tournamentsActive?.length > 0">{{ tournamentsActive?.length }}</span>
      </router-link>
    </li>
    <li>
      <router-link
        :to="{ name: 'levels' }"
        class="top-menu-link top-menu-link-levels"
        exact
        active-class="top-menu-link-active"
      >
        {{ t('menu.levels', 'Уровни') }}
      </router-link>
    </li>
    <li>
      <router-link
        :to="{ name: 'promo' }"
        class="top-menu-link top-menu-link-promo"
        exact
        active-class="top-menu-link-active"
      >
        {{ t('menu.promo', 'Промо') }}
      </router-link>
    </li>
    <li>
      <router-link
        :to="{ name: 'wins' }"
        class="top-menu-link top-menu-link-wins"
        exact
        active-class="top-menu-link-active"
      >
        {{ t('menu.wins', 'Победители') }}
      </router-link>
    </li>
    <li>
      <router-link
        :to="{ name: 'jackpots' }"
        class="top-menu-link top-menu-link-jackpots"
        exact
        active-class="top-menu-link-active"
      >
        {{ t('menu.jackpots', 'Джекпоты') }}
      </router-link>
    </li>
    <li>
      <router-link
        :to="{ name: 'contacts' }"
        class="top-menu-link top-menu-link-contacts"
        exact
        active-class="top-menu-link-active"
      >
        {{ t('menu.contacts', 'Контакты') }}
      </router-link>
    </li>

    <div class="top-menu-shm" @click="this.openDropdown = !this.openDropdown" />
  </ul>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { Talkme } from '@/components/talkme'
import { mapGetters } from 'vuex'
import { OPEN_GLOBAL_POPUP } from '@/store/actions.type'

export default {
  name: 'TheMenu',
  data() {
    return {
      openDropdown: false,
      openMobNav: false,
    }
  },
  setup() {
    const { t, locale } = useI18n()
    return { t, locale }
  },
  computed: {
    ...mapGetters(['tournamentsActive', 'isAuthenticated']),
  },
  watch: {
    $route(to, from) {
      this.hideMobileNav()
    },
  },
  methods: {
    openMobileNav() {
      this.openMobNav = true
      this.$store.dispatch(OPEN_GLOBAL_POPUP, true)
      document.body.classList.add('noscroll')
    },
    hideMobileNav() {
      this.openMobNav = false
      this.$store.dispatch(OPEN_GLOBAL_POPUP, false)
      document.body.classList.remove('noscroll')
    },
    support() {
      Talkme.open()
    },
  },
}
</script>

<style scoped></style>
