import { LOGIN, LOGOUT, REGISTER, CHECK_AUTH, UPDATE_USER, USER_STATUS, PASSWORD_FORGOT } from './actions.type'
import { SET_AUTH, PURGE_AUTH, SET_ERROR, SET_STATUS } from './mutations.type'

import ApiService from '@/common/api.service'
import { API_REALM, NO_MIGRATE } from '@/common/config'
import JwtService, { getToken, parsedToken } from '@/common/jwt.service'
import { doMigrateOnToken } from '@/common/migrate.service'

const state = {
  errors: null,
  user: {
    profile: {},
    status: {},
  },
  isAuthenticated: !!JwtService.getToken(),
}

const getters = {
  currentUser(state) {
    return state.user
  },
  isAuthenticated(state) {
    return state.isAuthenticated
  },
}

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post('users/login', { ...credentials, realm: API_REALM })
        .then(({ data }) => {
          context.commit(SET_AUTH, data)

          doMigrateOnToken()

          resolve(data)
        })
        .catch(({ response }) => {
          reject(response)
        })
    })
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH)
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post('users/register', { ...credentials, realm: API_REALM })
        .then(({ data }) => {
          context.commit(SET_AUTH, data)
          resolve(data)
        })
        .catch(({ response }) => {
          reject(response)
        })
    })
  },
  [CHECK_AUTH](context) {
    if (JwtService.getToken()) {
      return ApiService.get('user/refresh-token')
        .then(({ data }) => {
          context.commit(SET_AUTH, data)
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response?.data.error)
          // context.commit(PURGE_AUTH)
        })
    } else {
      context.commit(PURGE_AUTH)
    }
  },
  [PASSWORD_FORGOT](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post('users/forgot', { ...credentials, realm: API_REALM })
        .then(({ data }) => {
          resolve(data)
        })
        .catch(({ response }) => {
          reject(response)
        })
    })
  },
  [USER_STATUS](context) {
    if (JwtService.getToken()) {
      return ApiService.get('user')
        .then(({ data }) => {
          context.commit(SET_STATUS, data?.data)
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response?.data?.error)
          // context.commit(PURGE_AUTH)
        })
    } else {
      context.commit(PURGE_AUTH)
    }
  },
  [UPDATE_USER](context, payload) {
    const { lang, password, display_name } = payload
    const user = {
      lang,
      display_name,
      password: undefined,
    }
    if (password) {
      user.password = password
    }

    return ApiService.put('user', user).then(({ data }) => {
      context.dispatch(USER_STATUS)
      return data
    })
  },
}

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error
  },
  [SET_AUTH](state, data) {
    state.isAuthenticated = true
    state.user = data.user
    state.errors = {}
    JwtService.saveToken(data.access_token)
  },
  [SET_STATUS](state, data) {
    state.user = data
    state.errors = {}
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false
    state.user = {}
    state.errors = {}
    JwtService.destroyToken()
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
}
