<template>
  <div class="color-pay-system">
    <img src="/images/pay/9.svg" alt="" />
    <img src="/images/pay/1.svg" alt="" />
    <img src="/images/pay/2.svg" alt="" />
    <img src="/images/pay/3.svg" alt="" />
    <img src="/images/pay/4.svg" alt="" />
    <img src="/images/pay/6.svg" alt="" />
    <img src="/images/pay/7.svg" alt="" />
    <img src="/images/pay/8.svg" alt="" class="piastrix" />
  </div>
</template>

<script>
export default {
  name: 'PlayLine',
}
</script>

<style scoped></style>
