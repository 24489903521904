<template>
  <div
    :class="`over regist ${isActiveNav('#reg-success') || isActiveNav('#reg-success-phone') ? 'active' : ''}`"
    @click.stop="closeDialog"
  >
    <div class="regist-1" @click.stop>
      <div class="logo-user"><img src="/images/level1.svg" alt="" /></div>
      <div class="title">Поздравляем с регистрацией!</div>
      <div class="text">Вы получили уровень <span>«Новичок»</span></div>
      <div class="block">
        <div class="block-1">
          <div class="title-color">Вам доступны бонусы</div>
          <ul class="bonus">
            <swiper
              :slides-per-view="1"
              :modules="modules"
              :navigation="{ nextEl: '.footer-slider-next', prevEl: '.footer-slider-prev' }"
              :pagination="{ clickable: true }"
              :breakpoints="{
                '350': {
                  slidesPerView: 1,
                },
                '600': {
                  slidesPerView: 1,
                },
                '1350': {
                  slidesPerView: 3,
                },
              }"
            >
              <swiper-slide
                ><li><span>100%</span> на 1-й депозит</li></swiper-slide
              >
              <swiper-slide
                ><li><span>150%</span> на 2-й депозит</li></swiper-slide
              >
              <swiper-slide
                ><li><span>+ 30 FS</span> фриспинов</li></swiper-slide
              >
            </swiper>
          </ul>
        </div>
        <div class="block-2">
          <template v-if="isActiveNav('#reg-success')">
            <a href="#" class="title-color2"><img src="/images/regist-add.svg" />Подтвердите почту</a>
            <div class="text">Вам на почту отправлено письмо &mdash; подтвердите его для получения бонусов.</div>
          </template>
          <template v-else>
            <a href="#" class="title-color2"><img src="/images/regist-add-2.svg" />Проверьте СМС</a>
            <div class="text">На Ваш номер телефона был отправлен логин и пароль для входа на сайт.</div>
          </template>
          <div class="btn">
            <a href="#cashdesk" class="btn-1">Перейти в кассу</a>
            <router-link :to="{ name: 'levels' }" class="btn-2">Подробнее</router-link>
          </div>
        </div>
      </div>
      <div class="text-footer">
        <p>
          Достигни следующего уровня <span class="level">«Бронзовый»</span> и получи
          <span class="color">Бонус 100 ₽ и кэшбек 3%.</span>
        </p>
      </div>
      <div class="close" @click.stop="closeDialog"></div>
    </div>
  </div>
</template>

<style scoped></style>

<script>
import { Autoplay, Pagination, Navigation } from 'swiper/modules'
import { getDesiredLocale, setLocale } from '@/common/locale'
import { useI18n } from 'vue-i18n'
import UserLevelImg from '@/components/UserLevelImg.vue'
import { Swiper, SwiperSlide } from 'swiper/vue'

export default {
  name: 'RegisterSuccessModal',
  data() {
    return {
      openLang: false,
      lang: 'RU',
    }
  },
  components: {
    UserLevelImg,
    Swiper,
    SwiperSlide,
  },
  setup() {
    const { t, locale } = useI18n()

    return {
      modules: [Autoplay, Pagination, Navigation],
      t,
    }
  },
  created() {
    this.lang = getDesiredLocale()
  },
  methods: {
    isActiveNav(elem) {
      if (typeof elem === 'object') {
        return elem.includes(this.$route.hash)
      }
      return this.$route.hash === elem
    },
    closeDialog() {
      this.$router.push({ hash: '' })
    },
  },
}
</script>
