<template>
  <div class="slot-slider-line">
    <div class="slot-slider-title" style="padding-left: 8px">
      <img src="/images/proizv.svg" alt="" />
      <span>{{ t('vendors', 'Провайдеры') }}</span>
      <a href="/vendor" class="">Все </a>
    </div>

    <div class="slider-games main-brands-slider">
      <swiper
        :slides-per-view="1"
        :modules="modules"
        :navigation="{ nextEl: '.manufacture-next', prevEl: '.manufacture-prev' }"
        :breakpoints="{
          '500': {
            slidesPerView: 2,
          },
          '700': {
            slidesPerView: 3,
          },
          '950': {
            slidesPerView: 4,
          },
          '1180': {
            slidesPerView: 5,
          },
          '1400': {
            slidesPerView: 6,
          },
        }"
      >
        <!--        <swiper-slide v-for="manufacturer in manufacturers" :key="manufacturer.id">-->
        <!--          <div class="slider-brands-item">-->
        <!--            <a href="/vendor/greentube" class="slider-brands-item-image"-->
        <!--            ><img :src="manufacturer.icon" :alt="manufacturer.title"-->
        <!--            /></a>-->
        <!--          </div>-->
        <!--        </swiper-slide>-->

        <swiper-slide>
          <div class="slider-brands-item">
            <router-link
              :to="{ name: 'category-manufacturer', params: { manufacturer: 'greentube' } }"
              class="slider-brands-item-image"
              ><img src="/images/brands/brands-item1-green.svg" alt=""
            /></router-link>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="slider-brands-item">
            <router-link
              :to="{ name: 'category-manufacturer', params: { manufacturer: 'igrosoft' } }"
              class="slider-brands-item-image"
              ><img src="/images/brands/brands-item2-igrosoft.svg" alt=""
            /></router-link>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="slider-brands-item">
            <router-link
              :to="{ name: 'category-manufacturer', params: { manufacturer: 'netent' } }"
              class="slider-brands-item-image"
              ><img src="/images/brands/brands-item3-netend.svg" alt=""
            /></router-link>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="slider-brands-item">
            <router-link
              :to="{ name: 'category-manufacturer', params: { manufacturer: 'yggdrasil' } }"
              class="slider-brands-item-image"
              ><img src="/images/brands/brands-item4-yggdrasil.svg" alt=""
            /></router-link>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="slider-brands-item">
            <router-link
              :to="{ name: 'category-manufacturer', params: { manufacturer: 'playngo' } }"
              class="slider-brands-item-image"
              ><img src="/images/brands/brands-item5-playngo.svg" alt=""
            /></router-link>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="slider-brands-item">
            <router-link
              :to="{ name: 'category-manufacturer', params: { manufacturer: 'belatra' } }"
              class="slider-brands-item-image"
              ><img src="/images/brands/brands-item6-belatra.svg" alt=""
            /></router-link>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="slider-brands-item">
            <router-link
              :to="{ name: 'category-manufacturer', params: { manufacturer: 'microgaming' } }"
              class="slider-brands-item-image"
              ><img src="/images/brands/brands-item7-microgaming.svg" alt=""
            /></router-link>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="slider-brands-item">
            <router-link
              :to="{ name: 'category-manufacturer', params: { manufacturer: 'pragmaticplay' } }"
              class="slider-brands-item-image"
              ><img src="/images/brands/brands-item8-unicum.svg" alt=""
            /></router-link>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="slider-brands-item">
            <router-link
              :to="{ name: 'category-manufacturer', params: { manufacturer: 'megajack' } }"
              class="slider-brands-item-image"
              ><img src="/images/brands/brands-item9-megajack.svg" alt=""
            /></router-link>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="slider-brands-item">
            <router-link
              :to="{ name: 'category-manufacturer', params: { manufacturer: 'betinhell' } }"
              class="slider-brands-item-image"
              ><img src="/images/brands/brands-item10-betinhell.svg" alt=""
            /></router-link>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="slider-brands-item">
            <router-link
              :to="{ name: 'category-manufacturer', params: { manufacturer: 'pragmatic' } }"
              class="slider-brands-item-image"
              ><img src="/images/brands/brands-item11-pragmatic.svg" alt=""
            /></router-link>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="slider-brands-item">
            <router-link
              :to="{ name: 'category-manufacturer', params: { manufacturer: 'pushgaming' } }"
              class="slider-brands-item-image"
              ><img src="/images/brands/brands-item13-pushgaming.svg" alt=""
            /></router-link>
          </div>
        </swiper-slide>
      </swiper>

      <div class="slider-games-nav">
        <div class="slider-games-prev manufacture-prev slider-arrow" />
        <div class="slider-games-next manufacture-next slider-arrow slider-arrow-right" />
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation } from 'swiper/modules'
import { mapGetters } from 'vuex'

export default {
  name: 'Manufacturers',
  components: {
    Swiper,
    SwiperSlide,
  },
  computed: {
    ...mapGetters(['manufacturers']),
  },
  setup() {
    const { t } = useI18n()

    return { t, modules: [Navigation] }
  },
}
</script>

<style scoped>
.slider-brands-item {
  margin: 0 6px;
  height: 115px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.3);
}

.slider-brands-item-image {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease;
  background: rgba(0, 0, 0, 0);
}

.slider-brands-item:hover .slider-brands-item-image {
  background: rgba(0, 0, 0, 0.3);
}
</style>
