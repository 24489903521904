import { SEND_PHONE_VERIFICATION_CODE, VERIFY_PHONE_CODE } from './actions.type'

import ApiService, { BonusesService, VerificationService } from '@/common/api.service'
import { SET_STATUS } from '@/store/mutations.type'

const actions = {
  async [SEND_PHONE_VERIFICATION_CODE](context, { phone }) {
    return VerificationService.sendPhoneVerificationCode(phone)
      .then(() => {
        return {
          success: true,
          message: 'Проверочный код отправлен на ваш номер телефона.',
          secondsLeft: 0,
        }
      })
      .catch(({ response }) => {
        switch (response.status) {
          case 404:
            return {
              success: false,
              message: 'Превышено количество попыток верификации. Обратитесь в службу поддержки.',
            }
          case 409:
            return {
              success: false,
              message: 'Номер телефона уже верифицирован. Для смены номера обратитесь в службу поддержки.',
            }
          case 429:
            return {
              success: false,
              message: 'Слишком частые попытки отправки кода.',
              secondsLeft: response?.data?.data?.secondsLeft,
            }
          default:
            return {
              success: false,
              message:
                'Произошла ошибка отправки кода. Проверьте указанный номер телефона и попробуйте снова, или обратитесь в службу поддержки',
            }
        }
      })
  },
  async [VERIFY_PHONE_CODE](context, { code }) {
    return VerificationService.verifyPhoneCode(code)
      .then(({ data }) => {
        if (data?.data?.user) {
          context.commit(SET_STATUS, data?.data.user)
        }
        return {
          success: data.data?.success,
          message: data.data?.message ? data.data.message + '. Обратитесь в службу поддержки.' : null,
        }
      })
      .catch(({ response }) => {
        switch (response.status) {
          default:
            return {
              success: false,
              message: 'Произошла ошибка верификации кода. Попробуйте позже или обратитесь в службу поддержки',
            }
        }
      })
  },
}

export default {
  actions,
}
