<template>
  <div class="valute-select">
    <div class="valute-select-main" @click="this.openCurrency = !this.openCurrency">
      <div class="valute-select-item">
        <span>{{ currencies[value] }}</span>
      </div>
    </div>
    <div class="valute-select-hidden" v-if="this.openCurrency" @click="this.openCurrency = false">
      <div class="valute-select-item" @click="setValue('RUB')">
        <span>{{ currencies.RUB }}</span>
      </div>
      <!--      <div v-if="value !== 'USD'" class="valute-select-item" @click="setValue('USD')">-->
      <!--        <span>{{ currencies.USD }}</span>-->
      <!--      </div>-->
      <!--      <div v-if="value !== 'EUR'" class="valute-select-item" @click="setValue('EUR')">-->
      <!--        <span>{{ currencies.EUR }}</span>-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'

const currencies = {
  RUB: '₽',
  // USD: '$',
  // EUR: '€',
}

export default {
  name: 'Currency',
  props: ['setValue', 'value'],
  setup() {
    const { t } = useI18n()
    return { t, currencies }
  },
  data() {
    return {
      openCurrency: false,
    }
  },
}
</script>

<style scoped></style>
