import { ACTIVATE_BONUS, CASHBACK, FETCH_BONUSES, FETCH_JACKPOT, FETCH_JACKPOT_WINNERS } from './actions.type'
import { SET_BONUSES, SET_JACKPOT, SET_JACKPOT_WINNERS, SET_STATUS } from './mutations.type'

import ApiService, { BonusesService } from '@/common/api.service'

const state = {
  bonuses: [],
  jackpot: null,
  jackpotWinners: [],
}

const getters = {
  bonuses(state) {
    return state.bonuses
  },
  jackpot(state) {
    return state.jackpot
  },
  jackpotWinners(state) {
    return state.jackpotWinners
  },
}

const actions = {
  [FETCH_BONUSES](context) {
    return BonusesService.query()
      .then(({ data }) => {
        context.commit(SET_BONUSES, data)
      })
      .catch(error => {
        // console.log(error)
      })
  },
  [ACTIVATE_BONUS](context, { promoCode }) {
    return BonusesService.activate(promoCode)
      .then(({ data }) => {
        const success = !!data.data.actionRequired
        if (data?.data?.user) {
          context.commit(SET_STATUS, data?.data.user)
        }
        return {
          success: success,
          actionRequired: data.data.actionRequired,
          message: data.data.message,
        }
      })
      .catch(({ response }) => {
        if (response.status == 409) {
          return {
            success: false,
            message: response?.data?.err ? response.data.err : 'Ошибка активации бонуса',
          }
        } else {
          return {
            success: false,
            message: 'Ошибка активации бонуса. Попробуйте позже или обратитесь в службу поддержки.',
          }
        }
      })
  },
  [CASHBACK](context) {
    return BonusesService.cashback()
      .then(({ data }) => {
        if (data?.data?.user) {
          context.commit(SET_STATUS, data?.data.user)
        }
        return {
          success: true,
          message: data.data.message,
        }
      })
      .catch(({ response }) => {
        if (response.status != 200) {
          return {
            success: false,
            message: response?.data?.err ? response.data.err : 'Ошибка активации бонуса',
          }
        }
      })
  },
  [FETCH_JACKPOT](context) {
    return ApiService.get('jackpot')
      .then(({ data }) => {
        context.commit(SET_JACKPOT, data)
      })
      .catch(response => {})
  },
  [FETCH_JACKPOT_WINNERS](context) {
    return ApiService.get('jackpot/winners')
      .then(({ data }) => {
        context.commit(SET_JACKPOT_WINNERS, data)
      })
      .catch(response => {})
  },
}

/* eslint no-param-reassign: ["error", { "props": false }] */
const mutations = {
  [SET_BONUSES](state, { data }) {
    state.bonuses = data
  },
  [SET_JACKPOT](state, { data }) {
    state.jackpot = data
  },
  [SET_JACKPOT_WINNERS](state, { data }) {
    state.jackpotWinners = data
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
