export const fieldIsRequired = 'Поле обязательное'
export const phoneIsNotValid = 'Введите валидный телефон'
export const codeIsNotValid = 'Не валидный код страны'

export const minMaxValidation = (value, provider) => {
  const val = parseFloat(value || '0')
  if (val > provider?.max) {
    return `Максимум ${provider?.max} ₽`
  } else if (val < provider?.min) {
    return `Минимум ${provider?.min} ₽`
  }
  return true
}

export const requiredValidation = value => {
  if (!value || value?.length === 0) {
    return fieldIsRequired
  }
  return true
}

export const emailValidation = email => {
  return /.+@.+\..+/.test(email) ? null : 'Неправильный адрес'
}

export const passwordValidation = (login, password) => {
  if (password.length < 6) {
    return 'Пароль должен быть не меньше 6 символов'
  }
  if (login === password) {
    return 'Логин и пароль не должны совпадать'
  }
  if (!/[A-Za-z]/.test(password) || !/[0-9]/.test(password)) {
    return 'Пароль должен содержать буквы и цифры'
  }
  return null
}
