import auth from './auth.module'
import bonuses from './bonuses.module'
import games from './games.module'
import payments from './payments.module'
import profile from './profile.module'
import tournaments from './tournaments.module'
import news from './news.module'
import winners from './winners.module'
import verification from './verification.module'
import levels from './levels.module'
import global from './global.module'

import { createStore } from 'vuex'

export default createStore({
  modules: {
    games,
    tournaments,
    bonuses,
    winners,
    auth,
    // @ts-ignore
    // article,
    profile,
    payments,
    verification,
    levels,
    news,
    global,
  },
})
