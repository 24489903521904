export const FETCH_GAMES = 'fetchGames'
export const FETCH_GAME = 'fetchGame'
export const FETCH_TOURNAMENTS = 'fetchTournaments'
export const FETCH_TOURNAMENT = 'fetchTournament'
export const FETCH_NEWS = 'fetchNews'
export const FETCH_NEWS_ITEM = 'fetchNewsItem'
export const FETCH_BONUSES = 'fetchBonuses'
export const FETCH_WINNERS = 'fetchWinners'
export const LOGIN = 'login'
export const LOGOUT = 'logout'
export const REGISTER = 'register'
export const UPDATE_USER = 'updateUser'
export const USER_STATUS = 'userStatus'
export const CHECK_AUTH = 'checkAuth'
export const FETCH_PROFILE = 'fetchProfile'
export const PAY = 'pay'
export const FETCH_CASHDESK_CONFIG = 'fetchCashdeskConfig'
export const FETCH_PAYSYSTEM_CONFIG = 'fetchPaySystemConfig'
export const SEND_PAYMENT = 'sendPayment'
export const WITHDRAW_PAYMENT = 'withdrawPayment'
export const REVERT_PAYMENT = 'revertPayment'
export const FETCH_PENDING_PAYOUTS = 'fetchPendingPayments'
export const FETCH_PAYMENT_STATUS = 'fetchPaymentStatus'
export const PASSWORD_FORGOT = 'passwordForgot'
export const CHANGE_PROFILE = 'changeProfile'
export const CHANGE_PASSWORD = 'changePassword'
export const GET_PAYMENTS_HISTORY = 'getPaymentsHistory'
export const GET_BETS_HISTORY = 'getBetsHistory'
export const FETCH_JACKPOT = 'getJackpot'
export const FETCH_JACKPOT_WINNERS = 'getJackpotWinners'
export const FETCH_MESSAGES = 'fetchMail'
export const READ_MESSAGE = 'readMessage'
export const ACTIVATE_BONUS = 'activateBonus'
export const CASHBACK = 'cashback'

export const FETCH_FAVOURITES = 'fetchFavourites'
export const REMOVE_FROM_FAVOURITE = 'removeFromFavourites'
export const ADD_TO_FAVOURITE = 'addToFavourites'

export const SEND_PHONE_VERIFICATION_CODE = 'sendPhoneVerificationCode'
export const VERIFY_PHONE_CODE = 'verifyPhoneCode'
export const OPEN_GLOBAL_POPUP = 'openGlobalPopup'
