<template>
  <top-banner />

  <div class="head-line">
    <div class="head-line-w">
      <the-menu />

      <div class="logo">
        <router-link :to="{ name: 'home' }" />
        <img class="logobg" src="/images/Frame7.svg" alt="" />
        <img class="logobg-mob" src="/images/Frame8.svg" alt="" />
        <img class="greenl" src="/images/green.svg" alt="" />
        <object class="logoobj" type="image/svg+xml" data="/images/mln-logo-ani(4).svg" />
      </div>

      <top-user v-if="isAuthenticated" />
      <top-guest v-else />
    </div>
  </div>
</template>

<script>
import TopUser from '@/components/TopUser'
import TopGuest from '@/components/TopGuest'
import TopBanner from '@/components/TopBanner'
import TheMenu from '@/components/TheMenu'
import { mapGetters } from 'vuex'

export default {
  name: 'TheHeader',
  components: {
    TopGuest,
    TheMenu,
    TopBanner,
    TopUser,
  },
  computed: {
    ...mapGetters(['currentUser', 'isAuthenticated']),
  },
}
</script>

<style scoped>
.head-line {
  position: relative;
  background: #002a01;
  box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.25);
  z-index: 1000;
}
.head-line-w {
  padding: 0 65px;
  margin: 0 auto;
  display: flex;
  height: 80px;
  justify-content: space-between;
  align-items: center;
  text-align: left;
}
.head-line-fixed {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 80px;
  z-index: 2223;
}
@media (max-width: 1500px) {
  .head-line-w {
    padding-left: 20px;
    padding-right: 20px;
  }
}
</style>
