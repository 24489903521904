<template>
  <img :src="'/images/level' + level + '.svg'" alt="" :style="style" />
</template>

<script>
export default {
  name: 'UserLevelImg',
  props: {
    level: {
      type: Number,
      required: true,
    },
    style: {
      type: Object,
      required: false,
    },
  },
}
</script>
