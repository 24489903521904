<template>
  <!--Миграция-->
  <div :class="`over migrated ${isActiveNav('#migrated') ? 'active' : ''}`" @click.stop="closeDialog">
    <div class="regist-1" @click.stop>
      <div class="title">Вы перенаправлены</div>
      <div class="text">
        на домен <span>«{{ domain }}»</span> &mdash; адрес для ВИП игроков клуба. Настоятельно рекомендуем Вам сохранить
        данный домен в закладки и всегда использовать для постоянного доступа к сайту в Вашем регионе.
      </div>
      <div class="btn">
        <a href="#" class="btn-2" @click.stop="closeDialog">Хорошо</a>
      </div>
      <div class="close" @click.stop="closeDialog"></div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Autoplay, Pagination, Navigation } from 'swiper/modules'
import { getDesiredLocale, setLocale } from '@/common/locale'
import { useI18n } from 'vue-i18n'
import { mapGetters } from 'vuex'
import UserLevelImg from '@/components/UserLevelImg.vue'
import AuthenticatedModals from '@/components/modals/AuthenticatedModals.vue'

export default {
  name: 'NewLevelModal',
  data() {
    return {
      openLang: false,
      lang: 'RU',
    }
  },
  components: {
    AuthenticatedModals,
    UserLevelImg,
    Swiper,
    SwiperSlide,
  },
  setup() {
    const { t, locale } = useI18n()

    return {
      modules: [Autoplay, Pagination, Navigation],
      t,
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'currentUser', 'levelByIndex']),
    domain() {
      return window.location.hostname
    },
  },
  created() {
    this.lang = getDesiredLocale()

    this.$watch('currentUser', (value, old) => {
      // watch for a new level
      if (old?.status?.cur_level && value?.status?.cur_level !== old?.status?.cur_level) {
        this.$router.push({ hash: '#new-level' })
      }
    })
  },
  methods: {
    isActiveNav(elem) {
      if (typeof elem === 'object') {
        return elem.includes(this.$route.hash)
      }
      return this.$route.hash === elem
    },
    closeDialog() {
      this.$router.push({ hash: '' })
    },
    curLevel() {
      return this.levelByIndex(this.currentUser?.status?.cur_level - 1)
    },
    nextLevel() {
      return this.levelByIndex(this.currentUser?.status?.next_level - 1)
    },
  },
}
</script>
