export const FETCH_END = 'setGames'
export const FETCH_START = 'setLoading'
export const SET_TOURNAMENTS = 'setTournaments'
export const SET_TOURNAMENT = 'setTournament'
export const SET_NEWS = 'setNews'
export const SET_NEWS_ITEM = 'setNewsItem'
export const SET_WINNERS_WEEK = 'setWinnersWeek'
export const SET_WINNERS_MONTH = 'setWinnersMonth'
export const SET_BONUSES = 'setBonuses'
export const PURGE_AUTH = 'logOut'
export const SET_GAME = 'setGame'
export const SET_AUTH = 'setUser'
export const SET_STATUS = 'setStatus'
export const RESET_STATE = 'resetModuleState'
export const SET_ERROR = 'setError'
export const SET_PROFILE = 'setProfile'
export const SET_PAYMENT_RESULT = 'setPaymentResult'
export const SET_CASHDESK_CONFIG = 'setCashdeskConfig'
export const SET_FAVOURITES = 'setFavourites'
export const SET_JACKPOT = 'setJackpot'
export const SET_PENDING_PAYOUTS = 'setPendingPayouts'
export const SET_JACKPOT_WINNERS = 'setJackpotWinners'
export const SET_MESSAGES = 'setMessages'
export const MARK_MESSAGE_READ = 'markMessageRead'
export const SET_GLOBAL_POPUP_OPEN = 'setGlobalPopupOpen'
