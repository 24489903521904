<template>
  <div class="profile-block">
    <div class="history-table-wrap" v-if="!isLoading && list?.messages">
      <table class="history-table history-table3">
        <thead>
          <tr class="first-tr">
            <td>Дата</td>
            <td>Сообщение</td>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
      <template v-for="m in list.messages" :key="m.id">
        <message
          :id="m.id"
          :is-read="m.recipient.is_read"
          :is-mass="m.is_mass"
          :message="m.message"
          :topic="m.topic"
          :created_at="m.created_at"
          @read="readMessage"
        />
      </template>
    </div>
    <div v-else-if="isLoading">Загрузка...</div>
    <div v-else>Сообщений пока нет</div>
  </div>
</template>

<script>
import { format } from 'date-fns'
import { toast } from 'vue3-toastify'
import { useI18n } from 'vue-i18n'
import { FETCH_MESSAGES, READ_MESSAGE } from '@/store/actions.type'
import Message from '@/components/cashdesk/components/Message.vue'

export default {
  name: 'Mails',
  components: { Message },
  setup() {
    const { t, d } = useI18n()
    return { t, d }
  },
  data() {
    return {
      page: 0,
      tab: 0,
      list: null,
      isLoading: true,
      format,
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store
        .dispatch(FETCH_MESSAGES, {})
        .then(data => {
          this.list = data
          this.isLoading = false
        })
        .catch(res => {
          toast.error(res?.data?.err)
          this.isLoading = false
        })
    },
    readMessage(id) {
      this.$store.dispatch(READ_MESSAGE, { messageId: id }).catch(res => {
        toast.error(res?.data?.err)
      })
    },
  },
}
</script>

<style scoped></style>
