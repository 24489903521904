<template>
  <div :class="`slider-games-item ${game.labels?.join(' ')}`">
    <router-link
      v-if="game?.manufacturer"
      :to="{ name: 'category-manufacturer', params: { manufacturer: game?.manufacturer } }"
      class="link-vendor"
      ><div :class="'game-vendor game-mml game-mml-' + game?.manufacturer">
        <i>{{ t('vendorTitles.' + game?.manufacturer, game?.manufacturer) }}</i>
      </div>
    </router-link>

    <a href="#" class="slider-games-item-image">
      <img
        v-lazy="{ src: game.icon, loading: '/images/mln-logo-preloader-game.svg' }"
        :alt="game.title"
        width="164"
        height="164"
        src=""
      />
    </a>

    <div class="slider-games-item-over">
      <router-link v-if="isAuthenticated" :to="{ name: 'game', params: { game: game.slug } }" class="sgio-link"
        ><i>Играть</i><span>{{ game.title }}</span>
      </router-link>

      <router-link :to="{ name: 'demo', params: { game: game.slug } }" class="sgio-link"
        ><i>Демо</i><span>{{ game.title }}</span>
      </router-link>

      <a v-if="!this.favourites?.includes(game.slug)" @click="this.addToFavourite()" class="slider-games-likes" />
      <a v-else @click="this.removeFromFavourite()" class="slider-games-likes active" />
      <a href="#" class="slider-games-brands"></a>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { mapGetters } from 'vuex'
import { ADD_TO_FAVOURITE, REMOVE_FROM_FAVOURITE } from '@/store/actions.type'

export default {
  name: 'GameBlock',
  props: {
    game: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n()
    return { t }
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'favourites', 'categoryBySlug']),
  },
  methods: {
    addToFavourite() {
      if (this.isAuthenticated) {
        this.$store.dispatch(ADD_TO_FAVOURITE, { slug: this.game.slug })
      } else {
        this.$router.push({ name: 'register' })
      }
    },
    removeFromFavourite() {
      this.$store.dispatch(REMOVE_FROM_FAVOURITE, { slug: this.game.slug })
    },
  },
}
</script>

<style scoped>
[lazy='loading'] {
  width: 70%;
  display: block;
  margin: auto;
}
</style>
