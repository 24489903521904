import { FETCH_NEWS_ITEM, FETCH_NEWS } from './actions.type'
import { SET_NEWS, SET_NEWS_ITEM } from './mutations.type'

import { NewsService } from '@/common/api.service'

const state = {
  news: [],
  newsItem: null,
  isLoading: true,
}

const getters = {
  news(state) {
    return state.news
  },
  newsItem(state) {
    return state.newsItem
  },
}

const actions = {
  [FETCH_NEWS](context) {
    return new Promise((resolve, reject) => {
      return NewsService.query()
        .then(({ data }) => {
          context.commit(SET_NEWS, data)
          resolve(data)
        })
        .catch(response => {
          reject(response)
        })
    })
  },
  [FETCH_NEWS_ITEM](context, newsItemSlug) {
    context.commit(SET_NEWS_ITEM, null)

    return new Promise((resolve, reject) => {
      return NewsService.get(newsItemSlug)
        .then(({ data }) => {
          context.commit(SET_NEWS_ITEM, data.data)
          resolve(data)
        })
        .catch(response => {
          reject(response)
        })
    })
  },
}

/* eslint no-param-reassign: ["error", { "props": false }] */
const mutations = {
  [SET_NEWS](state, { data }) {
    state.news = data
  },
  [SET_NEWS_ITEM](state, data) {
    state.newsItem = data
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
