import App from './App.vue'
import router from './router'
import store from './store'

import { createApp } from 'vue'
import LoadScript from 'vue-plugin-load-script'
import './registerServiceWorker'

import DefaultLayout from './layouts/DefaultLayout.vue'
import AuthLayout from './layouts/AuthLayout.vue'
import GameLayout from './layouts/GameLayout.vue'

import { CHECK_AUTH, USER_STATUS, FETCH_JACKPOT, LOGOUT } from '@/store/actions.type'
import i18n from './i18n'

import { getDesiredLocale, setLocale } from '@/common/locale'
import Vue3Toastify, { type ToastContainerOptions, toast } from 'vue3-toastify'
import { Talkme } from '@/components/talkme'
import VueLazyLoad from 'vue3-lazyload'
import { migrateUser } from '@/common/migrate.service'

// Ensure we checked auth before each page load.
function refreshToken() {
  if (store.getters.isAuthenticated) {
    store.dispatch(CHECK_AUTH).then(() => {
      // setLocale(getDesiredLocale())
      setLocale('RU')
    })
  }

  setTimeout(refreshToken, 30 * 60 * 1000)
}

migrateUser()
// setLocale(getDesiredLocale())
setLocale('RU')
refreshToken()

function updateStatus() {
  if (store.getters.isAuthenticated) {
    store.dispatch(USER_STATUS).then(() => {
      Talkme.setUserInfo(store.getters.currentUser)
    })
  }
  store.dispatch(FETCH_JACKPOT)
  setTimeout(updateStatus, 10 * 1000)
}
setTimeout(updateStatus, 10 * 1000)

const app = createApp(App)

app.component('default-layout', DefaultLayout)
app.component('auth-layout', AuthLayout)
app.component('game-layout', GameLayout)

app
  .use(i18n)
  .use(router)
  .use(store)
  .use(LoadScript)
  .use(Vue3Toastify, {
    autoClose: 2000,
    position: toast.POSITION.BOTTOM_RIGHT,
    hideProgressBar: true,
    limit: 1,
  } as ToastContainerOptions)
  .use(VueLazyLoad, {
    // options...
  })
  .mount('#app')
