<template>
  <div class="tournaments-main">
    <div class="tournaments-main-left">
      <div class="slot-slider-title slot-slider-small">
        <img src="/images/summer-games.svg" alt="" />Турниры
        <span class="slot-slider-small-title">{{ tournament.title }}</span>
        <router-link :to="{ name: 'tournaments' }" class="all" v-if="this.$route.name === 'home'">Все</router-link>
      </div>
      <div class="tournaments-main-slider">
        <div class="slider-tournaments-item">
          <div class="slider-tournaments-item-image">
            <img :src="tournament.img" :alt="tournament.title" />

            <div class="tournaments-main-slider-timer" v-if="tournament.status === 'active'">
              <vue-countdown
                :time="getTime(new Date(tournament.date_end)) - new Date().getTime()"
                :transform="transformSlotProps"
                v-slot="{ days, hours, minutes, seconds }"
              >
                <div class="timeTo timeTo-white" style="font-size: 18px">
                  <div class="first" v-if="days !== 0">
                    {{ days }}
                  </div>
                  <span v-if="days !== 0" style="margin-left: 5px; margin-right: 5px">дней,</span>
                  <div class="first">
                    {{ hours }}
                  </div>
                  <span>:</span>
                  <div class="first">
                    {{ minutes }}
                  </div>
                  <span>:</span>
                  <div>
                    {{ seconds }}
                  </div>
                </div>
              </vue-countdown>
            </div>
          </div>

          <div class="slider-tournaments-item-label">
            <div class="slider-tournaments-item-title">Призовой фонд {{ tournament.prize }}</div>
            <router-link
              :to="{ name: 'tournament', params: { slug: tournament.slug } }"
              class="slider-tournaments-item-link"
            >
              Подробнее
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="tournaments-main-right">
      <div class="tournaments-table-wrap" v-if="tournament.status !== 'scheduled'">
        <table class="tournaments-table">
          <thead>
            <tr>
              <td class="place-col">Место</td>
              <td class="login-col">Логин</td>
              <td class="balls-col">Баллы</td>
              <td class="win-col">Приз</td>
            </tr>
          </thead>
          <tbody v-if="tournament.status !== 'scheduled' || tournament?.winners?.length">
            <tr v-for="(winner, index) in tournament?.winners" :key="winner.id">
              <td>{{ index + 1 }}</td>
              <td>{{ winner.username }}</td>
              <td>
                <strong>{{ n(Math.round(winner.points)) }}</strong>
              </td>
              <td>
                <div class="tournaments-table-win-line-money" v-if="winner?.prize?.type === 'money'">
                  {{ winner?.prize?.prize }}
                </div>
                <div class="tournaments-table-win-line" v-else-if="winner?.prize?.type === 'prize'">
                  {{ winner?.prize?.prize }}
                </div>
                <div class="tournaments-table-win-line-fs" v-else-if="winner?.prize?.type === 'fs'">
                  {{ winner?.prize?.prize }}
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="4"><div>Нет победителей</div></td>
            </tr>
          </tbody>
        </table>
      </div>
      <template v-else>
        <div class="tournament-timer">
          <div class="tournament-timer-cd">
            <div class="tournament-timer-cd-1">До начала турнира осталось</div>
            <div class="tournament-timer-cd-2">
              <vue-countdown
                :time="getTime(new Date(tournament.date_start)) - new Date().getTime()"
                :transform="transformSlotProps"
                v-slot="{ days, hours, minutes, seconds }"
              >
                <div class="is-countdown">
                  <span class="countdown-row countdown-show4">
                    <span class="countdown-section green" v-if="days">
                      <span class="countdown-amount">{{ days }}</span>
                      <span class="countdown-period">ДД</span>
                    </span>
                    <span class="countdown-section ch">
                      <span class="countdown-amount">{{ hours }}</span>
                      <span class="countdown-period">ЧЧ</span>
                    </span>
                    <span class="countdown-section">
                      <span class="countdown-amount">{{ minutes }}</span>
                      <span class="countdown-period">ММ</span>
                    </span>
                    <span class="countdown-section">
                      <span class="countdown-amount">{{ seconds }}</span>
                      <span class="countdown-period">СС</span>
                    </span>
                  </span>
                </div>
              </vue-countdown>
            </div>
            <div class="tournament-timer-info">
              <div class="tournament-timer-info-line">
                <i>Начало: </i>{{ format(new Date(tournament.date_start), 'dd.MM.yyyy hh:mm') }}
              </div>
              <div class="tournament-timer-info-line">
                <i>Конец: </i>{{ format(new Date(tournament.date_end), 'dd.MM.yyyy hh:mm') }}
              </div>
            </div>
          </div>
          <div class="tournament-timer-gift">
            <div class="tournament-timer-cd-1">Призовой фонд</div>
            <div class="tournament-timer-gift-price">{{ tournament.prize }}</div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import VueCountdown from '@chenfengyuan/vue-countdown'
import { transformSlotProps } from '@/utils'
import { useI18n } from 'vue-i18n'
import { getTime, format } from 'date-fns'

export default {
  name: 'TournamentBlock',
  props: {
    tournament: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { t, n } = useI18n()
    return { t, n, getTime, transformSlotProps, format }
  },
  components: {
    VueCountdown,
  },
}
</script>

<style scoped></style>
