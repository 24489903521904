<template>
  <BonusActivationResult :activation-message="this.activationMessage" />

  <div class="phone-verification-bonuses" v-if="phoneVerification">
    <div class="title">Для получения бонуса вам необходимо подтвердить номер телефона.</div>
    <div>
      <VerificationPhone @confirmed="phoneConfirmed" />
    </div>
  </div>

  <div class="profile-bonus-items" v-else>
    <template v-for="bonus in currentUser.status.bonuses || []" :key="bonus.name">
      <CashdeskBonus :bonus="bonus" @activate="activate" />
    </template>
  </div>

  <div class="loader" v-if="loading">Подождите...</div>

  <div class="profile-bonus-wrap" v-if="!currentUser?.status.bonuses">
    <div class="profile-bonus-alert">Нет доступных бонусов.</div>
    <div class="profile-bonus-text">
      Если у Вас есть промокод, введите его на вкладке
      <a @click="this.$router.push({ hash: '#promocod' })" class="gotopromo">{{
        t('cash.promo-tab.promocode', 'Промокод')
      }}</a
      >.
    </div>
  </div>
</template>

<script>
import VueCountdown from '@chenfengyuan/vue-countdown'
import { useI18n } from 'vue-i18n'
import { mapGetters } from 'vuex'
import CashdeskBonus from '@/components/cashdesk/Bonuses/Bonus.vue'
import { ACTIVATE_BONUS } from '@/store/actions.type'
import { toast } from 'vue3-toastify'
import BonusActivationResult from '@/components/cashdesk/components/BonusActivationResult.vue'
import VerificationPhone from '@/components/cashdesk/Verification/Phone.vue'

export default {
  name: 'CashdeskBonuses',
  components: { VerificationPhone, BonusActivationResult, CashdeskBonus, VueCountdown },
  setup() {
    const { t, n } = useI18n()
    return { t, n }
  },
  data() {
    return {
      curNotif: null,
      activationMessage: null,
      phoneVerification: false,
      code: null,
      loading: false,
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
  methods: {
    activate(name) {
      this.code = null
      this.activationMessage = null
      this.loading = true
      this.$store
        .dispatch(ACTIVATE_BONUS, {
          promoCode: name,
        })
        .then(data => {
          if (data.actionRequired === 'userConfirmationRequired') {
            this.phoneVerification = true
            this.code = name
          } else {
            this.activationMessage = data
          }
        })
        .catch(res => {
          toast.error(res?.data?.err)
        })
        .finally(() => {
          this.loading = false
        })
    },
    phoneConfirmed() {
      this.phoneVerification = false
      this.activate(this.code)
    },
  },
}
</script>

<style scoped>
.phone-verification-bonuses .title {
  margin-bottom: 40px;
}
.phone-verification-bonuses {
  width: 300px;
  margin: 0 auto;
}
</style>
