<template>
  <the-header-game menu mobile-menu top-banner />
  <slot />

  <new-level-modal />
</template>

<script>
import TheHeaderGame from '@/components/TheHeaderGame'

import { useI18n } from 'vue-i18n'
import NewLevelModal from '@/components/modals/NewLevel.vue'

export default {
  name: 'ProfileLayout',
  setup() {
    const { t, n } = useI18n()
    return { t, n }
  },
  components: {
    NewLevelModal,
    TheHeaderGame,
  },
  methods: {
    isActiveNav(elem) {
      return this.$route.name === elem
    },
  },
}
</script>
