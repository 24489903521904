import { createRouter, createWebHistory } from 'vue-router'

import Home from '@/views/Home.vue'
import { getToken } from '@/common/jwt.service'

// export default createRouter({
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/category/:category',
      name: 'category',
      component: () => import(/* webpackChunkName: "Category", webpackPrefetch: true */ '@/views/Category.vue'),
    },
    {
      path: '/favourites',
      name: 'favourites',
      component: () => import(/* webpackChunkName: "Category", webpackPrefetch: true */ '@/views/Favourites.vue'),
      meta: {
        requiresAuth: true,
        savedPosition: true,
      },
    },
    {
      path: '/vendor/:manufacturer',
      name: 'category-manufacturer',
      component: () => import(/* webpackChunkName: "Category", webpackPrefetch: true */ '@/views/Category.vue'),
    },
    {
      path: '/topic/:topic',
      name: 'category-topic',
      component: () => import(/* webpackChunkName: "Category", webpackPrefetch: true */ '@/views/Category.vue'),
    },
    {
      path: '/search/:string',
      name: 'search',
      meta: {
        savedPosition: true,
      },
      component: () => import(/* webpackChunkName: "Search", webpackPrefetch: true */ '@/views/Home.vue'),
    },
    {
      name: 'login',
      path: '/login',
      component: () => import(/* webpackChunkName: "Login", webpackPrefetch: true */ '@/views/Login.vue'),
      meta: {
        layout: 'auth-layout',
      },
    },
    {
      name: 'register',
      path: '/register',
      component: () => import(/* webpackChunkName: "Register", webpackPrefetch: true */ '@/views/Register.vue'),
      meta: {
        layout: 'auth-layout',
      },
    },
    {
      name: 'restore',
      path: '/restore',
      component: () => import(/* webpackChunkName: "Register", webpackPrefetch: true */ '@/views/Restore.vue'),
      meta: {
        layout: 'auth-layout',
      },
    },
    {
      name: 'tournaments',
      path: '/tournaments/:page?',
      component: () => import(/* webpackChunkName: "Tournaments", webpackPrefetch: true */ '@/views/Tournaments.vue'),
    },
    {
      name: 'tournament',
      path: '/tournaments/:slug',
      component: () => import(/* webpackChunkName: "Tournaments", webpackPrefetch: true */ '@/views/Tournament.vue'),
    },
    {
      name: 'bonuses',
      path: '/bonuses',
      component: () => import(/* webpackChunkName: "Bonuses", webpackPrefetch: true */ '@/views/Bonuses.vue'),
    },
    // {
    //   name: 'achievements',
    //   path: '/achievements',
    //   component: () => import(/* webpackChunkName: "Achievements", webpackPrefetch: true */ '@/views/Achievements.vue'),
    // },
    {
      name: 'promo',
      path: '/promo',
      component: () => import(/* webpackChunkName: "Promo", webpackPrefetch: true */ '@/views/Promo.vue'),
    },
    {
      name: 'wins',
      path: '/wins',
      component: () => import(/* webpackChunkName: "Wins", webpackPrefetch: true */ '@/views/Wins.vue'),
    },
    {
      name: 'contacts',
      path: '/contacts',
      component: () => import(/* webpackChunkName: "Contacts", webpackPrefetch: true */ '@/views/Contacts.vue'),
    },
    {
      name: 'jackpots',
      path: '/jackpots',
      component: () => import(/* webpackChunkName: "Jackpots", webpackPrefetch: true */ '@/views/Jackpots.vue'),
    },
    {
      name: 'levels',
      path: '/levels',
      component: () => import(/* webpackChunkName: "Levels", webpackPrefetch: true */ '@/views/Levels.vue'),
    },
    {
      name: 'game',
      path: '/playroom/:game',
      meta: {
        layout: 'game-layout',
      },
      component: () => import(/* webpackChunkName: "Game", webpackPrefetch: false */ '@/views/Game.vue'),
    },
    {
      name: 'demo',
      path: '/playroom/:game/demo',
      meta: {
        layout: 'game-layout',
      },
      component: () => import(/* webpackChunkName: "GameDemo", webpackPrefetch: true */ '@/views/Game.vue'),
    },
    {
      name: 'news',
      path: '/news',
      component: () => import(/* webpackChunkName: "News", webpackPrefetch: true */ '@/views/News.vue'),
    },
    {
      name: 'faq',
      path: '/faq',
      component: () => import(/* webpackChunkName: "Faq", webpackPrefetch: true */ '@/views/Faq.vue'),
    },
    {
      name: 'rules',
      path: '/rules',
      component: () => import(/* webpackChunkName: "Rules", webpackPrefetch: true */ '@/views/Rules.vue'),
    },
    {
      name: 'vendor',
      path: '/vendor',
      component: () => import(/* webpackChunkName: "Vendor", webpackPrefetch: true */ '@/views/Vendor.vue'),
    },
    {
      name: 'topics',
      path: '/topics',
      component: () => import(/* webpackChunkName: "Topics", webpackPrefetch: true */ '@/views/Topics.vue'),
    },
    {
      path: '/:pathMatch(.*)*',
      component: () => import(/* webpackChunkName: "NotFound", webpackPrefetch: true */ '@/views/NotFound.vue'),
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({
            el: to.hash,
          })
        }, 500)
      })
    }
  },
} as any)

router.beforeEach((to, from) => {
  const isAuthenticated = getToken()

  if (!to.meta.savedPosition) {
    window.scrollTo({
      top: 0,
    })
  }

  if (!isAuthenticated && to.meta.requiresAuth) {
    return { name: 'login' }
  }

  return true
})

export default router
