<template>
  <div :class="`profile-bonus-item ${bonus.type} ${this.showDetails ? 'profile-bonus-item-active' : ''}`">
    <div class="profile-bonus-item-main" v-if="bonus.state === 1">
      <div v-html="bonus.title" :class="`profile-bonus-item-text ${bonus.type}`"></div>

      <vue-countdown :time="bonus.time_left * 1000" v-slot="{ days, hours, minutes, seconds }">
        <div class="profile-bonus-item-timer bonus-timer01">
          <div class="timeTo timeTo-white" style="font-size: 15px">
            <template v-if="days">
              <div class="first">
                {{ days }}
              </div>
              <span>:</span>
            </template>
            <div class="first">
              {{ hours.toString().padStart(2, '0') }}
            </div>
            <span>:</span>
            <div class="first">
              {{ minutes.toString().padStart(2, '0') }}
            </div>
            <span>:</span>
            <div>
              {{ seconds.toString().padStart(2, '0') }}
            </div>
          </div>
        </div>
      </vue-countdown>

      <a href="#" class="orange-btn profile-bonus-item-btn" @click.prevent="activate">Активировать</a>
      <div class="profile-bonus-item-info" v-if="bonus.wager">{{ bonus.wager }}</div>
      <div class="pbim-game" v-if="bonus.game">
        Игра:
        <router-link :to="{ name: 'game', params: { game: bonus.game } }">{{
          this.gameBySlug(bonus.game)?.title
        }}</router-link>
      </div>
      <div class="profile-bonus-item-type-r" v-if="bonus.type === 'bonus'">₽</div>
      <div class="profile-bonus-item-type-f" v-if="bonus.type === 'freespins'">fs</div>
    </div>

    <template v-else-if="bonus.state === 2">
      <div class="profile-bonus-item-main" v-if="bonus.type === 'bonus'">
        <div class="profile-bonus-item-title">Активный бонус</div>
        <div class="profile-bonus-item-title" v-if="bonus.bonus_amount > 0">{{ bonus.bonus_amount }} ₽</div>
        <div class="pbih-info">
          <span>Осталось ставок:</span><i>{{ bonus.bets_left }}&nbsp;₽</i>
        </div>
        <div class="profile-bonus-item-type-r">₽</div>
      </div>

      <div
        class="profile-bonus-item-main"
        v-else-if="bonus.type === 'freespins'"
        :set="(game = gameBySlug(bonus.game))"
      >
        <div class="pbim-fl">
          <img :src="game.icon" :alt="game.title" />
          <div class="pbim-fl-title">Активные<br />фриспины</div>
        </div>
        <div class="pbih-info">
          <span>Количество спинов:</span><i>{{ bonus.freespins_left }}</i>
        </div>
        <div class="pbim-game">
          Игра:
          <router-link :to="{ name: 'game', params: { game: game?.slug } }">{{ game?.title }} </router-link>
        </div>
        <div class="profile-bonus-item-type-f">fs</div>
      </div>
    </template>

    <div class="profile-bonus-item-hidden" v-if="this.showDetails">
      <div :class="`pbih-title ${bonus.type}`" v-html="bonus.title" />
      <div :class="`pbih-text ${bonus.type}`" v-html="bonus.descr" />
    </div>

    <a @click="this.showDetails = true" class="profile-bonus-item-q" v-if="bonus.descr">?</a>
    <a @click="this.showDetails = false" class="pbih-close"></a>
  </div>
</template>

<script>
import VueCountdown from '@chenfengyuan/vue-countdown'
import { useI18n } from 'vue-i18n'
import { mapGetters } from 'vuex'

export default {
  name: 'CashdeskBonus',
  components: { VueCountdown },
  props: ['bonus'],
  emits: ['activate'],
  setup() {
    const { t, n } = useI18n()
    return { t, n }
  },
  data() {
    return {
      showDetails: false,
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'gameBySlug', 'gameByAPIName']),
  },
  methods: {
    activate() {
      this.$emit('activate', this.bonus.name)
    },
  },
}
</script>

<style scoped></style>
