<template>
  <div class="main-menu-block">
    <ul class="main-menu">
      <li class="main-menu-fav">
        <router-link
          class="main-menu-link main-menu-link-heart"
          :to="{ name: isAuthenticated ? 'favourites' : 'register' }"
        />
      </li>
      <li
        :class="`brands-li mob-brands-li-ready-to-open ${this.brandHover ? 'menuactive' : ''}`"
        @mouseenter="this.brandHover = true"
        @mouseleave="this.brandHover = false"
      >
        <a href="#" class="main-menu-link mml-brands"><span>Все провайдеры</span></a>
        <div class="hidden-brands" v-if="this.brandHover">
          <div class="hidden-brands-fl">
            <router-link :to="{ name: 'category-manufacturer', params: { manufacturer: 'greentube' } }"
              ><img src="/images/providers/greentube.svg" alt=""
            /></router-link>
            <router-link :to="{ name: 'category-manufacturer', params: { manufacturer: 'netent' } }"
              ><img src="/images/providers/netent.svg" alt=""
            /></router-link>
            <router-link :to="{ name: 'category-manufacturer', params: { manufacturer: 'playngo' } }"
              ><img src="/images/providers/playgo.svg" alt=""
            /></router-link>
            <router-link :to="{ name: 'category-manufacturer', params: { manufacturer: 'yggdrasil' } }"
              ><img src="/images/providers/ygg.svg" alt=""
            /></router-link>
            <router-link :to="{ name: 'category-manufacturer', params: { manufacturer: 'microgaming' } }"
              ><img src="/images/providers/microgaming.svg" alt=""
            /></router-link>
            <router-link :to="{ name: 'category-manufacturer', params: { manufacturer: 'igrosoft' } }"
              ><img src="/images/providers/igrosoft.svg" alt=""
            /></router-link>
            <router-link :to="{ name: 'category-manufacturer', params: { manufacturer: 'belatra' } }"
              ><img src="/images/providers/belatra.svg" alt=""
            /></router-link>
            <router-link :to="{ name: 'category-manufacturer', params: { manufacturer: 'unicum' } }"
              ><img src="/images/providers/unicum.svg" alt=""
            /></router-link>
            <router-link :to="{ name: 'category-manufacturer', params: { manufacturer: 'megajack' } }"
              ><img src="/images/providers/megajack.svg" alt=""
            /></router-link>
            <router-link :to="{ name: 'category-manufacturer', params: { manufacturer: 'betinhell' } }"
              ><img src="/images/providers/betinhell.svg" alt=""
            /></router-link>
            <router-link :to="{ name: 'category-manufacturer', params: { manufacturer: 'pragmatic' } }"
              ><img src="/images/providers/pragmatic.svg" alt=""
            /></router-link>
            <router-link :to="{ name: 'category-manufacturer', params: { manufacturer: 'pushgaming' } }"
              ><img src="/images/providers/pushgaming.svg" alt=""
            /></router-link>
          </div>
        </div>
      </li>
      <li>
        <router-link :to="{ name: 'category', params: { category: 'new' } }" class="main-menu-link mml-new"
          ><span>Новинки</span></router-link
        >
      </li>
      <li>
        <router-link
          :to="{ name: 'category-manufacturer', params: { manufacturer: 'greentube' } }"
          class="main-menu-link mml-greentube"
          ><span>Greentube</span></router-link
        >
      </li>
      <li>
        <router-link
          :to="{ name: 'category-manufacturer', params: { manufacturer: 'igrosoft' } }"
          class="main-menu-link mml-game"
          ><span>Игрософт</span></router-link
        >
      </li>
      <li>
        <router-link
          :to="{ name: 'category-manufacturer', params: { manufacturer: 'netent' } }"
          class="main-menu-link mml-netent"
          ><span>NetEnt</span></router-link
        >
      </li>
      <li>
        <router-link
          :to="{ name: 'category-manufacturer', params: { manufacturer: 'pragmatic' } }"
          class="main-menu-link mml-pragmatic"
          ><span>PragmaticPlay</span></router-link
        >
      </li>
      <li>
        <router-link
          :to="{ name: 'category-manufacturer', params: { manufacturer: 'yggdrasil' } }"
          class="main-menu-link mml-yggdrasil"
          ><span>Yggdrasil</span></router-link
        >
      </li>
      <li>
        <router-link
          :to="{ name: 'category-manufacturer', params: { manufacturer: 'playngo' } }"
          class="main-menu-link mml-playngo"
          ><span>Playngo</span></router-link
        >
      </li>
    </ul>

    <form :class="`search-block ${this.activeSearch ? 'search-li-active' : ''}`" @submit.prevent="this.search">
      <input type="text" style="display: none" />
      <input type="password" style="display: none" />
      <input
        type="text"
        class="search-block-text"
        name="search"
        ref="search"
        v-model="this.searchValue"
        :placeholder="t('search', 'Найти игру...')"
        @focus="this.activeSearch = true"
        @blur="!this.searchValue ? (this.activeSearch = false) : ''"
      />
      <input type="submit" value="" name="" class="search-block-submit" />
    </form>
    <div class="mob-search"></div>
  </div>

  <div :class="`mob-additional-nav-wrap ${this.openDropdown ? 'active' : ''}`">
    <div
      class="mob-additional-pr mob-additional-pr-ready-to-open"
      @click=";(this.brandHover = !this.brandHover), (this.openDropdown = !this.openDropdown)"
    >
      {{ t('vendors', 'Производители') }}
    </div>
    <div class="hidden-brands" v-if="this.brandHover">
      <div class="hidden-brands-fl" @click="this.brandHover = false">
        <router-link :to="{ name: 'category-manufacturer', params: { manufacturer: 'greentube' } }"
          ><img src="/images/providers/greentube.svg" alt=""
        /></router-link>
        <router-link :to="{ name: 'category-manufacturer', params: { manufacturer: 'netent' } }"
          ><img src="/images/providers/netent.svg" alt=""
        /></router-link>
        <router-link :to="{ name: 'category-manufacturer', params: { manufacturer: 'playngo' } }"
          ><img src="/images/providers/playgo.svg" alt=""
        /></router-link>
        <router-link :to="{ name: 'category-manufacturer', params: { manufacturer: 'yggdrasil' } }"
          ><img src="/images/providers/ygg.svg" alt=""
        /></router-link>
        <router-link :to="{ name: 'category-manufacturer', params: { manufacturer: 'microgaming' } }"
          ><img src="/images/providers/microgaming.svg" alt=""
        /></router-link>
        <router-link :to="{ name: 'category-manufacturer', params: { manufacturer: 'igrosoft' } }"
          ><img src="/images/providers/igrosoft.svg" alt=""
        /></router-link>
        <router-link :to="{ name: 'category-manufacturer', params: { manufacturer: 'belatra' } }"
          ><img src="/images/providers/belatra.svg" alt=""
        /></router-link>
        <router-link :to="{ name: 'category-manufacturer', params: { manufacturer: 'unicum' } }"
          ><img src="/images/providers/unicum.svg" alt=""
        /></router-link>
        <router-link :to="{ name: 'category-manufacturer', params: { manufacturer: 'megajack' } }"
          ><img src="/images/providers/megajack.svg" alt=""
        /></router-link>
        <router-link :to="{ name: 'category-manufacturer', params: { manufacturer: 'betinhell' } }"
          ><img src="/images/providers/betinhell.svg" alt=""
        /></router-link>
        <router-link :to="{ name: 'category-manufacturer', params: { manufacturer: 'pragmatic' } }"
          ><img src="/images/providers/pragmatic.svg" alt=""
        /></router-link>
        <router-link :to="{ name: 'category-manufacturer', params: { manufacturer: 'pushgaming' } }"
          ><img src="/images/providers/pushgaming.svg" alt=""
        /></router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { debounce } from '@/utils'
import { mapGetters } from 'vuex'

export default {
  name: 'TheNav',
  data() {
    return {
      activeSearch: false,
      brandHover: false,
      searchValue: this.$route?.params?.string || null,
      openDropdown: false,
    }
  },
  setup() {
    const { t, locale } = useI18n()
    return { t, locale }
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
  },
  watch: {
    $route: function (newVal) {
      if (newVal?.hash === '#home') {
        this.searchValue = null
        this.activeSearch = null
      }
    },

    searchValue: debounce(function (newVal, oldVal) {
      try {
        if (newVal === '') {
          this.$router.push({ name: 'home' })
        } else {
          this.$router.push({ name: 'search', params: { string: newVal } })
        }
      } catch (e) {}
    }, 200),
  },
}
</script>
