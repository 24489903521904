import axios from 'axios'

import { API_REALM, API_URL } from '@/common/config'
import JwtService from '@/common/jwt.service'

import { toast } from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'
import store from '@/store'
import { LOGOUT } from '@/store/actions.type'
import { getDesiredLocale } from '@/common/locale'

export const axiosClient = axios.create({
  baseURL: API_URL,
})

axiosClient.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      if (error.response.status === 500) {
        // toast.error('Отсутствует интернет-соединение')
      } else if (error.response.status === 401) {
        store.dispatch(LOGOUT)
      } else if (error.response.status === 403) {
        store.dispatch(LOGOUT)
      }
    } else {
      toast.error('Отсутствует интернет-соединение')
    }

    return Promise.reject(error)
  }
)

const ApiService = {
  getConfig() {
    const headers = axios.defaults.headers
    if (JwtService.getToken()) {
      headers['Authorization'] = `Bearer ${JwtService.getToken()}`
    }
    headers['X-Realm'] = API_REALM
    headers['X-Location-Id'] = window.location.hostname
    headers['X-Language'] = getDesiredLocale()
    return {
      headers: headers,
    }
  },

  query(resource, params) {
    // TODO what to do with params? append to resource?
    return axiosClient.get(resource, { ...this.getConfig(), params })
  },

  get(resource, slug = '') {
    return axiosClient.get(`${resource}` + (slug ? `/${slug}` : ''), this.getConfig())
  },

  post(resource, params?: any) {
    return axiosClient.post(`${resource}`, params, this.getConfig())
  },

  update(resource, slug, params) {
    return axiosClient.put(`${resource}/${slug}`, params, this.getConfig())
  },

  put(resource, params) {
    return axiosClient.put(`${resource}`, params, this.getConfig())
  },

  delete(resource) {
    return axiosClient.delete(resource, this.getConfig())
  },
}

export default ApiService

export const GamesService = {
  query() {
    return ApiService.query('games', {})
  },
  get(slug, isDemo) {
    if (isDemo) {
      return ApiService.get('games', slug + '/demo')
    } else {
      return ApiService.get('user/game', slug)
    }
  },
}

export const TournamentsService = {
  query() {
    return ApiService.query('tournaments', {})
  },
  get(slug) {
    return ApiService.get('tournaments', slug)
  },
}

export const BonusesService = {
  query() {
    return ApiService.query('bonuses', {})
  },
  get(slug) {
    return ApiService.get('bonuses', slug)
  },
  activate(promoCode: string) {
    return ApiService.post('user/bonuses/activate', { promoCode: promoCode })
  },
  cashback() {
    return ApiService.post('user/bonuses/cashback')
  },
}

export const ProfileService = {
  get(slug) {
    return ApiService.get('user', slug)
  },
}

export const WinnersService = {
  query(slug, params) {
    return ApiService.query(`winners${slug}`, params)
  },
}

export const VerificationService = {
  sendPhoneVerificationCode(phone: string) {
    return ApiService.post('user/confirmation/phone/send-code', { phone: phone })
  },
  verifyPhoneCode(code: string) {
    return ApiService.post('user/confirmation/phone/verify', { code: code })
  },
}

export const NewsService = {
  query() {
    return ApiService.query('news', {})
  },
  get(slug) {
    return ApiService.get('news', slug)
  },
}

// export const CommentsService = {
//   get(slug) {
//     if (typeof slug !== "string") {
//       throw new Error(
//         "[YDACHA] CommentsService.get() article slug required to fetch comments"
//       );
//     }
//     return ApiService.get("articles", `${slug}/comments`);
//   },
//
//   post(slug, payload) {
//     return ApiService.post(`articles/${slug}/comments`, {
//       comment: { body: payload }
//     });
//   },
//
//   destroy(slug, commentId) {
//     return ApiService.delete(`articles/${slug}/comments/${commentId}`);
//   }
// };
//
// export const FavoriteService = {
//   add(slug) {
//     return ApiService.post(`articles/${slug}/favorite`);
//   },
//   remove(slug) {
//     return ApiService.delete(`articles/${slug}/favorite`);
//   }
// };
