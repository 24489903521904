export const showBlockForPayment = (providers, providerIndex, index) => {
  const countRows = Math.ceil(providers?.length / 4)
  const lastElemIndex = providers.length - 1

  for (let i = 0; i < countRows; i++) {
    const len = 4 * (i + 1) - 1
    const newN = len > lastElemIndex ? lastElemIndex : len
    const newI = i * 4

    if (providerIndex >= newI && providerIndex <= len && index === newN) {
      return true
    }
  }

  return false
}
