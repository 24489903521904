<template>
  <div class="activation-message {{activationMessage?.success ? 'success' : 'fail'}}" v-if="activationMessage">
    <span class="message">{{ activationMessage?.message }}</span>
  </div>
</template>

<script>
export default {
  name: 'BonusActivationResult',
  props: ['activationMessage'],
}
</script>

<style scoped>
.activation-message {
  margin: 30px;
}
.verify {
  display: inline-block;
  margin: 10px;
}
</style>
