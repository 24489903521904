export default {
  "menu": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
    "popular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popular"])},
    "slots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slots"])},
    "live": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Live Dealers"])},
    "tables": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tables"])},
    "fav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favourites"])},
    "tournaments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tournaments"])},
    "bonuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonuses"])},
    "achievements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achievements"])},
    "levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levels"])},
    "jackpots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jackpots"])},
    "wins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Winners"])},
    "promo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promo"])},
    "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])}
  },
  "auth": {
    "log-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log In"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
    "forgot-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password?"])}
  },
  "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance"])},
  "cashback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cashback"])},
  "cashdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cashdesk"])},
  "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level"])},
  "vendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendors"])},
  "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "online-support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online Support"])},
  "we-social-networks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find us in social networks"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
  "see-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See all"])},
  "all-games": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All games"])},
  "game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game"])},
  "users-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Winners"])},
  "win-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Win"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
  "to-play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play"])},
  "demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demo"])},
  "slots-themes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slots Themes"])},
  "summer-games": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Летние игры"])},
  "games": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Games"])},
  "game-day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Игра дня"])},
  "cash": {
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "finance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finance"])},
    "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bid history"])},
    "payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payments"])},
    "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mail"])},
    "promo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promo"])},
    "profile-tab": {
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
      "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Data"])},
      "verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
      "all-levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All levels"])},
      "next-level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next level"])},
      "next-level-bonus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On the next level you will receive:"])},
      "your": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your"])},
      "to-level-left": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("points")), " Points left to<br /> the next level \"", _interpolate(_named("level")), "\""])},
      "account-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account status:"])},
      "not-verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not verified"])},
      "verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verified"])}
    },
    "finance-tab": {
      "in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash In"])},
      "out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash Out"])},
      "payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payments history"])},
      "bids": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bids history"])},
      "select-payway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select payment method:"])},
      "select-amount-or-input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select or input an amount:"])},
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
      "add-money": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add money"])},
      "take-money": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take cash"])}
    },
    "promo-tab": {
      "bonuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonuses"])},
      "cashback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cashback"])},
      "promocode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promocode"])}
    }
  },
  "USD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$"])},
  "RUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["₽"])},
  "levels": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newbie"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bronze"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silver"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gold"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platinum"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diamond"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vip"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vip1"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vip2"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vip3"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vip4"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vip5"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vip6"])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vip7"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vip8"])},
    "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vip9"])},
    "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vip10"])},
    "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Super VIP"])},
    "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legendary"])},
    "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium"])},
    "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High Roller"])},
    "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Millionaire"])}
  }
}