<template>
  <!--Новый уровень-->
  <div :class="`over new-level ${isActiveNav('#new-level') ? 'active' : ''}`" @click.stop="closeDialog">
    <div class="regist-1" @click.stop>
      <div class="logo-user">
        <UserLevelImg :level="currentUser?.status?.cur_level" />
      </div>
      <div class="title">Поздравляем! Новый уровень</div>
      <div class="text">
        Вы получили уровень <span>«{{ t('levels.' + currentUser?.status?.cur_level) }}»</span>
      </div>
      <ul class="bonus">
        <li>
          Вам доступен бонус <span>{{ curLevel()?.bonus }}</span>
        </li>
        <li v-if="curLevel()?.cashback > 0">
          Ваш кешбэк увеличен до <span>{{ curLevel()?.cashback }}%</span>
        </li>
        <li>А также бездепозитные <span>FS</span></li>
      </ul>
      <div class="text1">с повышением уровня Ваш лимит вывода увеличен.</div>
      <div class="btn">
        <router-link :to="{ hash: '#bonus' }" class="btn-1">Подробнее о бонусе</router-link>
        <a href="#" class="btn-2" @click.stop="closeDialog">Хорошо</a>
      </div>
      <div class="text-footer">
        <p>
          Достигни следующего уровня
          <span class="level">«{{ t('levels.' + currentUser?.status?.next_level) }}»</span> и получи
          <span class="color" style="white-space: nowrap"
            >{{ nextLevel()?.bonus
            }}<template v-if="nextLevel()?.cashback > 0"> и кэшбек {{ nextLevel()?.cashback }}%</template>.</span
          >
        </p>
      </div>
      <div class="close" @click.stop="closeDialog"></div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Autoplay, Pagination, Navigation } from 'swiper/modules'
import { getDesiredLocale, setLocale } from '@/common/locale'
import { useI18n } from 'vue-i18n'
import { mapGetters } from 'vuex'
import UserLevelImg from '@/components/UserLevelImg.vue'
import AuthenticatedModals from '@/components/modals/AuthenticatedModals.vue'

export default {
  name: 'NewLevelModal',
  data() {
    return {
      openLang: false,
      lang: 'RU',
    }
  },
  components: {
    AuthenticatedModals,
    UserLevelImg,
    Swiper,
    SwiperSlide,
  },
  setup() {
    const { t, locale } = useI18n()

    return {
      modules: [Autoplay, Pagination, Navigation],
      t,
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'currentUser', 'levelByIndex']),
  },
  created() {
    this.lang = getDesiredLocale()

    this.$watch('currentUser', (value, old) => {
      // watch for a new level
      if (old?.status?.cur_level && value?.status?.cur_level !== old?.status?.cur_level) {
        this.$router.push({ hash: '#new-level' })
      }
    })
  },
  methods: {
    isActiveNav(elem) {
      if (typeof elem === 'object') {
        return elem.includes(this.$route.hash)
      }
      return this.$route.hash === elem
    },
    closeDialog() {
      this.$router.push({ hash: '' })
    },
    curLevel() {
      return this.levelByIndex(this.currentUser?.status?.cur_level - 1)
    },
    nextLevel() {
      return this.levelByIndex(this.currentUser?.status?.next_level - 1)
    },
  },
}
</script>
