export const transformSlotProps = props => {
  const formattedProps = {}

  Object.entries(props).forEach(([key, value]) => {
    // @ts-ignore
    formattedProps[key] = value < 10 ? `0${value}` : String(value)
  })

  return formattedProps
}
