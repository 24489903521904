<template>
  <!--Mobil menu-->
  <div class="mobil-menu">
    <div class="mobil-1">
      <div class="mobil-1-1">{{ t('menu.menu', 'Меню') }}</div>
      <div class="mobil-1-2"></div>
    </div>
    <ul>
      <li class="slot">
        <a href="#">{{ t('menu.slots', 'Слоты') }}</a>
      </li>
      <li class="new">
        <a href="#">{{ t('menu.new', 'Новинки') }}</a>
      </li>
      <li class="popular">
        <a href="#">{{ t('menu.popular', 'Популярные') }}</a>
      </li>
      <li class="tournament">
        <router-link :to="{ name: 'tournaments' }">{{ t('menu.tournaments', 'Турниры') }}</router-link>
      </li>
      <li class="bonuses">
        <router-link :to="{ name: 'bonuses' }">{{ t('menu.bonuses', 'Бонусы') }}</router-link>
      </li>
      <li class="achievements">
        <router-link :to="{ name: 'bonuses' }">{{ t('menu.achievements', 'Достижения') }}</router-link>
      </li>
      <li class="level">
        <router-link :to="{ name: 'levels' }">{{ t('menu.levels', 'Уровни') }}</router-link>
      </li>
    </ul>
    <a href="#" class="oline-support" @click.prevent="support">{{ t('online-support', 'Онлайн-поддержка') }}</a>
    <div class="mobil-2">
      <p>{{ t('we-social-networks', 'Мы в социальных сетях:') }}</p>
      <ul>
        <li><a href="#" class="tl">Telegram</a></li>
        <li><a href="#" class="yt"></a></li>
      </ul>
    </div>
  </div>
  <div class="mobil-fon"></div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { Talkme } from '@/components/talkme'

export default {
  name: 'MobileMenu',
  setup() {
    const { t } = useI18n()
    return { t }
  },
  methods: {
    support() {
      Talkme.open()
    },
  },
}
</script>

<style scoped></style>
