<template>
  <v-form class="pay-select-summ-hid" @submit="onSubmit" @invalid-submit="onInvalidSubmit" validate-on-mount>
    <div class="pay-select-summ pay-select-summ-column">
      <div class="pay-select-top">
        <div class="enter-card">
          <div class="enter-card-title">
            Телефон:<i v-if="provider.controllerParams?.note">{{ provider.controllerParams.note }}</i>
          </div>

          <div class="field-block-tel">
            <vue-tel-input
              v-model="phonenumber"
              @validate="phoneValidate"
              mode="international"
              :inputOptions="{ placeholder: 'Введите номер', showDialCode: true }"
              :preferredCountries="['RU', 'UA', 'KZ']"
              :dropdownOptions="{
                disabled: false,
                showDialCodeInSelection: true,
                showFlags: true,
                showDialCodeInList: true,
                showSearchBox: false,
                tabindex: 0,
              }"
              class="enter-card-input"
            />
            <span class="validate-alert" v-if="this.phoneValidationText">{{ this.phoneValidationText }}</span>
          </div>

          <div class="save-card">
            <button
              :class="`enter-card-favorite ${isSaveWallet ? 'active' : ''}`"
              @click="isSaveWallet = !isSaveWallet"
            />
            <div class="save-card-btn">Сохранить телефон</div>
          </div>
        </div>

        <div class="enter-card">
          <div class="pay-select-summ-caption">Сумма:</div>

          <div class="field-block">
            <v-field
              name="amount"
              type="number"
              class="suminput"
              v-model="amount"
              :rules="minMax"
              mode="aggressive"
              maxlength="7"
              v-on:keypress="numbersOnly"
            />
            <div class="rub-static"><span>&#8381;</span></div>
            <div class="pm-block">
              <div class="max-block" @click="setMaxAmount">макс</div>
              <div class="pm-block-btn">
                <div class="plus" @click="inc(500)">+</div>
                <div class="minus" @click="inc(-500)">-</div>
              </div>
            </div>
            <error-message name="amount" />
          </div>
        </div>

        <button class="pay-order-btn" type="submit" :disabled="this.isLoading">
          {{ !isLoading ? 'Получить' : 'Ожидайте...' }}
        </button>
      </div>

      <div class="pay-select-bot">
        <div class="enter-card">
          <div class="field-block">
            <select name="bank" id="bank" class="enter-card-input" v-model="this.bank">
              <option value="" disabled>Выберите банк</option>
              <option v-for="bank in this.banksList" :key="bank.id" :value="bank.id">
                {{ bank.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </v-form>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { Field, Form, ErrorMessage } from 'vee-validate'
import AwaitingPayment from '../TopUp/AwaitingPayment'
import { minMaxValidation, phoneIsNotValid } from '@/utils'
import { VueTelInput } from 'vue-tel-input'
import { FETCH_PAYSYSTEM_CONFIG, FETCH_PENDING_PAYOUTS, WITHDRAW_PAYMENT } from '@/store/actions.type'
import axios from 'axios'
import { mapGetters } from 'vuex'
import { toast } from 'vue3-toastify'

export default {
  name: 'SbprubpayPayment',
  props: {
    provider: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { t, n } = useI18n()
    return { t, n }
  },
  data() {
    return {
      isLoading: false,
      bank: '',
      card: null,
      amount: null,
      phone: null,
      phoneValidationText: null,
      submitted: false,
      phonenumber: null,
      validNumber: '',
      banksList: [],
      isSaveWallet: false,
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
  mounted() {
    if (this.provider?.controllerParams?.bankListFile) {
      axios.get(this.provider?.controllerParams?.bankListFile).then(data => {
        this.banksList = data.data
      })
    }
    this.$store.dispatch(FETCH_PAYSYSTEM_CONFIG, this.provider.slug).then(cfg => {
      if (cfg.wallet) {
        this.phonenumber = '+' + cfg.wallet.replace(/[^0-9]/g, '')
      }
      this.isSaveWallet = cfg.isSaveWallet
    })
  },
  components: {
    VForm: Form,
    VField: Field,
    ErrorMessage,
    AwaitingPayment,
    VueTelInput,
  },
  methods: {
    phoneValidate(phoneObject) {
      if (phoneObject.valid) {
        this.validNumber = phoneObject.number
      } else {
        this.validNumber = ''
      }
    },
    minMax(value) {
      return minMaxValidation(value, this.provider)
    },
    inc(amount) {
      this.amount = parseFloat(this.amount || '0') + amount
      if (this.amount < this.provider?.min) {
        this.amount = this.provider?.min
      } else if (this.amount > this.provider?.max) {
        this.amount = this.provider?.max
      }

      if (this.amount > this.currentUser?.status?.balance) {
        this.amount = Math.floor(this.currentUser?.status?.balance)
      }
    },
    setMaxAmount() {
      this.amount = Math.floor(this.currentUser?.status?.balance)
      this.inc(0)
    },
    onInvalidSubmit() {
      if (!this.validNumber) {
        this.phoneValidationText = phoneIsNotValid
      } else {
        this.phoneValidationText = null
      }
    },
    onSubmit() {
      if (!this.validNumber) {
        this.phoneValidationText = phoneIsNotValid
      } else {
        this.phoneValidationText = null

        this.isLoading = true
        this.$store
          .dispatch(WITHDRAW_PAYMENT, {
            provider: this.provider.slug,
            amount: this.amount,
            currency: 'RUB',
            locationId: window.location.href,
            wallet: this.validNumber,
            extra: {
              bank_id: this.bank,
            },
            isSaveWallet: this.isSaveWallet,
          })
          .then(({ data }) => {
            this.show = true

            if (!data?.success) {
              toast.error(data?.msg)
              this.$emit('error')
            } else {
              toast.info(data?.msg)
              this.$emit('success')
            }
            this.$store.dispatch(FETCH_PENDING_PAYOUTS)

            this.isLoading = false
          })
      }
    },
    numbersOnly(evt) {
      evt = evt ? evt : window.event
      const charCode = evt.which ? evt.which : evt.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    },
  },
}
</script>

<style scoped></style>
