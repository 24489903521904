import { createI18n, LocaleMessages, VueMessageType } from 'vue-i18n'

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages(): LocaleMessages<VueMessageType> {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages: LocaleMessages<VueMessageType> = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key).default
    }
  })
  return messages
}
export default createI18n({
  legacy: false,
  locale: navigator.language, // process.env.VUE_APP_I18N_LOCALE || "ru",
  fallbackLocale: 'ru', //process.env.VUE_APP_I18N_FALLBACK_LOCALE || "ru",
  // @ts-ignore
  messages: loadLocaleMessages(),
  numberFormats: {
    en: {
      currency: {
        style: 'currency',
        currency: 'USD',
        currencyDisplay: 'symbol',
      },
      RUB: {
        style: 'currency',
        currency: 'RUB',
        currencyDisplay: 'symbol',
      },
      RUB_short: {
        style: 'currency',
        currency: 'RUB',
        currencyDisplay: 'symbol',
        maximumFractionDigits: 0,
      },
      USD: {
        style: 'currency',
        currency: 'USD',
        currencyDisplay: 'symbol',
      },
      USD_short: {
        style: 'currency',
        currency: 'USD',
        currencyDisplay: 'symbol',
        maximumFractionDigits: 0,
      },
    },
    ru: {
      currency: {
        style: 'currency',
        currency: 'RUB',
        currencyDisplay: 'symbol',
      },
      RUB: {
        style: 'currency',
        currency: 'RUB',
        currencyDisplay: 'symbol',
      },
      RUB_short: {
        style: 'currency',
        currency: 'RUB',
        currencyDisplay: 'symbol',
        maximumFractionDigits: 0,
      },
      USD: {
        style: 'currency',
        currency: 'USD',
        currencyDisplay: 'symbol',
      },
      USD_short: {
        style: 'currency',
        currency: 'USD',
        currencyDisplay: 'symbol',
        maximumFractionDigits: 0,
      },
      UAH: {
        style: 'currency',
        currency: 'UAH',
        currencyDisplay: 'symbol',
      },
      UAH_short: {
        style: 'currency',
        currency: 'UAH',
        currencyDisplay: 'symbol',
        maximumFractionDigits: 0,
      },
    },
  },
})
