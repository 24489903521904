<template>
  <div class="pending-payouts" v-if="pendingPayouts.length">
    <div class="payout">
      <span class="carefully"
        >У вас есть необработанный платеж на сумму
        {{ n(pendingPayouts[0].amount, currentUser?.currency || 'RUB') }}</span
      >
      <button @click="returnWithdraw(pendingPayouts[0].hash)" :disabled="loading">
        {{ !loading ? 'Вернуть' : 'Подождите...' }}
      </button>
    </div>
  </div>
</template>

<script>
import { FETCH_PENDING_PAYOUTS, REVERT_PAYMENT } from '@/store/actions.type'
import { mapGetters } from 'vuex'
import { useI18n } from 'vue-i18n'
import { toast } from 'vue3-toastify'

export default {
  name: 'PendingPayouts',
  data() {
    return {
      loading: false,
    }
  },
  setup() {
    const { t, n } = useI18n()
    return { t, n }
  },
  mounted() {
    this.refresh()
  },
  computed: {
    ...mapGetters(['pendingPayouts', 'currentUser']),
  },
  methods: {
    refresh() {
      return this.$store.dispatch(FETCH_PENDING_PAYOUTS)
    },
    returnWithdraw(hash) {
      this.loading = true
      this.$store
        .dispatch(REVERT_PAYMENT, hash)
        .then(data => {
          this.refresh().then(() => {
            toast.info('Сумма возвращена на ваш счёт.')
            this.loading = false
          })
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
  },
}
</script>

<style scoped>
.activation-message {
  margin: 30px;
}
.verify {
  display: inline-block;
  margin: 10px;
}
</style>
