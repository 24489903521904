import { FETCH_TOURNAMENT, FETCH_TOURNAMENTS } from './actions.type'
import { SET_TOURNAMENTS, SET_TOURNAMENT } from './mutations.type'

import { TournamentsService } from '@/common/api.service'

const state = {
  tournaments: [],
  tournament: null,
  isLoading: true,
}

const getters = {
  tournamentsTypesLastActive(state) {
    return state.tournaments?.find(elem => elem.status === 'active') || null
  },
  tournaments(state) {
    return state.tournaments
  },
  tournamentsActive(state) {
    return state.tournaments?.filter(elem => elem.status === 'active')
  },
  tournament(state) {
    return state.tournament
  },
}

const actions = {
  [FETCH_TOURNAMENTS](context) {
    return new Promise((resolve, reject) => {
      return TournamentsService.query()
        .then(({ data }) => {
          context.commit(SET_TOURNAMENTS, data)
          resolve(data)
        })
        .catch(response => {
          reject(response)
        })
    })
  },
  [FETCH_TOURNAMENT](context, tournamentSlug) {
    context.commit(SET_TOURNAMENT, null)

    return new Promise((resolve, reject) => {
      return TournamentsService.get(tournamentSlug)
        .then(({ data }) => {
          context.commit(SET_TOURNAMENT, data.data)
          resolve(data)
        })
        .catch(response => {
          reject(response)
        })
    })
  },
}

/* eslint no-param-reassign: ["error", { "props": false }] */
const mutations = {
  [SET_TOURNAMENTS](state, { data }) {
    state.tournaments = data
  },
  [SET_TOURNAMENT](state, data) {
    state.tournament = data
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
