<template>
  <tournament-block :tournament="tournamentsTypesLastActive" v-if="tournamentsTypesLastActive" />
</template>

<script>
import { FETCH_TOURNAMENTS } from '@/store/actions.type'
import { mapGetters } from 'vuex'
import TournamentBlock from '@/components/tournaments/TournamentBlock'

export default {
  name: 'HomeMiddleBlock',
  computed: {
    ...mapGetters(['tournamentsTypesLastActive']),
  },
  components: {
    TournamentBlock,
  },
  created() {
    this.$store.dispatch(FETCH_TOURNAMENTS)
  },
}
</script>

<style scoped></style>
