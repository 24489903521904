import { FETCH_WINNERS } from './actions.type'
import { SET_WINNERS_MONTH, SET_WINNERS_WEEK } from './mutations.type'

import { WinnersService } from '@/common/api.service'

const state = {
  winners: {
    week: [],
    month: [],
  },
}

const getters = {
  winnersWeek(state) {
    return state.winners.week
  },
  winnersMonth(state) {
    return state.winners.month
  },
}

const actions = {
  [FETCH_WINNERS](context, payload) {
    return WinnersService.query('', payload)
      .then(({ data }) => {
        if (payload?.period === 'month') {
          context.commit(SET_WINNERS_MONTH, data)
        } else {
          context.commit(SET_WINNERS_WEEK, data)
        }
      })
      .catch(error => {
        // console.log(error)
      })
  },
}

/* eslint no-param-reassign: ["error", { "props": false }] */
const mutations = {
  [SET_WINNERS_WEEK](state, { data }) {
    state.winners.week = data
  },
  [SET_WINNERS_MONTH](state, { data }) {
    state.winners.month = data
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
