<template>
  <div class="profile-data-col">
    <div class="profile-data-col-title">Сменить пароль</div>
    <VForm class="profile-data-col-block" autocomplete="off" @submit="this.onSubmit" :validation-schema="this.schema">
      <div class="profile-data-line profile-data-line-password">
        <div class="profile-data-line-title">Текущий пароль:</div>
        <VField name="username" type="hidden" :value="currentUser?.username" />

        <VField name="password" type="password" class="input-standart" placeholder="пароль" mode="aggressive" />
        <i class="icp"></i>
        <ErrorMessage name="password" />
      </div>
      <div class="profile-data-line profile-data-line-password">
        <div class="profile-data-line-title">Новый пароль:</div>

        <VField name="newPassword" type="password" class="input-standart" placeholder="пароль" mode="aggressive" />
        <i class="icp"></i>
        <ErrorMessage name="newPassword" />
      </div>
      <div class="profile-data-line profile-data-line-password">
        <div class="profile-data-line-title">Повторите пароль</div>
        <i class="icp"></i>
        <VField
          name="repeatNewPassword"
          type="password"
          class="input-standart"
          placeholder="пароль"
          mode="aggressive"
        />
        <i class="icp"></i>
        <ErrorMessage name="repeatNewPassword" />
      </div>
      <button type="submit" class="orange-btn" :disabled="this.isLoading">Сменить пароль</button>
      <div class="profile-data-alert">
        Используйте сложные пароли. Минимум 6 символов, пароль должен отличаться от логина и содержать цифры и буквы.
      </div>
    </VForm>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Field, Form, ErrorMessage } from 'vee-validate'
import * as yup from 'yup'
import { CHANGE_PASSWORD } from '@/store/actions.type'
import { toast } from 'vue3-toastify'

export default {
  name: 'Profile',
  data() {
    return {
      schema: yup.object().shape({
        password: yup.string().required('Поле обязательное'),
        newPassword: yup
          .string()
          .min(6, 'Введите минимум 6 символов')
          .notOneOf([yup.ref('username')], 'Пароль должен отличаться от логина')
          .test('is valid password', 'Пароль должен содержать буквы и цифры', value => {
            return /[A-Za-z]/.test(value) && /[0-9]/.test(value)
          })
          .required('Поле обязательное'),
        repeatNewPassword: yup
          .string()
          .required('Поле обязательное')
          .oneOf([yup.ref('newPassword')], 'Пароль не совпадает'),
      }),
    }
  },
  components: {
    VForm: Form,
    VField: Field,
    ErrorMessage,
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
  methods: {
    onSubmit(props) {
      this.isLoading = true

      this.$store
        .dispatch(CHANGE_PASSWORD, { old_password: props.password, new_password: props.newPassword })
        .then(({ data }) => {
          toast.success('Пароль изменён')
          this.isLoading = false
        })
        .catch(res => {
          toast.error(res?.data?.err)
          this.isLoading = false
        })
    },
  },
  mounted() {
    let showPassword = document.querySelectorAll('.icp')
    showPassword.forEach(item => item.addEventListener('click', toggleType))
    function toggleType() {
      let input = this.closest('.profile-data-line-password').querySelector('.input-standart')
      if (input.type === 'password') {
        input.type = 'text'
      } else {
        input.type = 'password'
      }
    }
  },
}
</script>

<style scoped>
.orange-btn {
  border: none;
  cursor: pointer;
}
.icp {
  top: 22px;
}
</style>
