import { OPEN_GLOBAL_POPUP } from './actions.type'
import { SET_GLOBAL_POPUP_OPEN } from '@/store/mutations.type'

const state = {
  globalPopupOpen: false,
}

const getters = {
  globalPopupOpen(state) {
    return state.globalPopupOpen
  },
}

const actions = {
  [OPEN_GLOBAL_POPUP](context, isOpen: boolean) {
    context.commit(SET_GLOBAL_POPUP_OPEN, isOpen)
  },
}

/* eslint no-param-reassign: ["error", { "props": false }] */
const mutations = {
  [SET_GLOBAL_POPUP_OPEN](state, isOpen: boolean) {
    state.globalPopupOpen = isOpen
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
