import { FETCH_GAME, FETCH_GAMES } from './actions.type'
import { FETCH_START, FETCH_END, SET_GAME } from './mutations.type'

import { GamesService } from '@/common/api.service'

const state = {
  games: [],
  game: {},
  categories: [],
  manufacturers: [],
  topics: [],
  isLoading: true,
  cache: {},
}

const getters = {
  games(state) {
    return state.games
  },
  gameBySlug: state => slug => {
    return state.games.find(game => game.slug === slug)
  },
  gameByAPIName: state => (platformId, gameId) => {
    return state.games.find(game => (platformId === 1 ? game.apiName === 'm_' + gameId : game.apiName === gameId))
  },
  gamesByCategory: state => categorySlug => {
    return state.games.filter(game => game.categories.includes(categorySlug))
  },
  gamesByManufacturer: state => manufacturerSlug => {
    return state.games.filter(game => game.manufacturer === manufacturerSlug)
  },
  gamesByTopic: state => topicSlug => {
    return state.games.filter(game => game.topics?.includes(topicSlug))
  },
  gamesSearch: state => query => {
    const lcQuery = query.toLowerCase()
    return state.games.filter(
      game =>
        game.slug.toLowerCase().includes(lcQuery) ||
        game.title.toLowerCase().includes(lcQuery) ||
        game.tags.toLowerCase().includes(lcQuery)
    )
  },
  gameManufacturer: (state, getters) => slug => {
    const game = getters.gameBySlug(slug)
    return game ? getters.manufacturerBySlug(game.manufacturer) : undefined
  },
  manufacturerBySlug: state => slug => {
    const found = state.manufacturers.find(m => m.slug === slug)
    return found ? found : {}
  },
  categoryBySlug: state => slug => {
    const found = state.categories.find(c => c.slug === slug)
    return found ? found : {}
  },
  topicBySlug: state => slug => {
    const found = state.topics.find(t => t.slug === slug)
    return found ? found : {}
  },
  game(state) {
    return state.game
  },
  categories(state) {
    return state.categories
  },
  manufacturers(state) {
    return state.manufacturers
  },
  topics(state) {
    return state.topics
  },
  isLoading(state) {
    return state.isLoading
  },
}

const actions = {
  async [FETCH_GAMES]({ commit }, params) {
    commit(FETCH_START)

    return GamesService.query()
      .then(({ data }) => {
        commit(FETCH_END, data.data)
      })
      .catch(error => {
        // console.log(error)
      })
  },
  async [FETCH_GAME](context, { slug, isDemo }) {
    // avoid extraneous network call if article exists
    // if (prevGame !== undefined) {
    //   return context.commit(SET_GAME, prevGame);
    // }
    const { data } = await GamesService.get(slug, isDemo)
    if (data.data?.type === 'game') {
      context.commit(SET_GAME, data.data)
    } else {
      context.commit(SET_GAME, {})
    }
    return data.data
  },
}

/* eslint no-param-reassign: ["error", { "props": false }] */
const mutations = {
  [FETCH_START](state) {
    state.isLoading = true
  },
  [FETCH_END](state, { games, categories, manufacturers, topics }) {
    state.games = games
    state.categories = categories
    state.manufacturers = manufacturers
    state.topics = topics
    state.isLoading = false
  },
  [SET_GAME](state, data) {
    state.game = data
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
