<template>
  <div class="profile-block">
    <div class="werif-wrap">
      <verification-email v-if="false" />
      <verification-phone :focused="!!phone" />
      <verification-documents v-if="false" />
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { mapGetters } from 'vuex'
import VerificationPhone from '@/components/cashdesk/Verification/Phone.vue'
import VerificationEmail from '@/components/cashdesk/Verification/Email.vue'
import VerificationDocuments from '@/components/cashdesk/Verification/Documents.vue'

export default {
  name: 'Verification',
  components: { VerificationDocuments, VerificationEmail, VerificationPhone },
  props: ['phone'],
  setup() {
    const { t } = useI18n()
    return { t }
  },
  data() {},
  computed: {
    ...mapGetters(['currentUser']),
  },
}
</script>

<style scoped></style>
