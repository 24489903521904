<template>
  <a class="profile-back" @click="this.$router.push({ hash: '' })">Назад</a>
  <div class="modal-wrap">
    <div class="modal-overlay" @click="this.$router.push({ hash: '' })" />
    <div class="modal-content modal-profile">
      <div class="profile-wrap" v-if="!this.isLoading && currentUser?.username">
        <div class="profile-head">
          <div class="profile-head-info">
            <div class="profile-head-level">
              <div class="profile-head-level-image">
                <UserLevelImg :level="currentUser?.status.cur_level" />
              </div>
              <div class="profile-head-level-t">
                <div class="profile-head-level-name">
                  {{ t('levels.' + currentUser?.status?.cur_level) }}
                </div>
                <div class="profile-head-level-progress">
                  <div
                    class="profile-head-level-progress-current"
                    :style="`width: ${currentUser?.status?.next_level_progress?.percent}%`"
                  ></div>
                </div>
                <span class="profile-head-level-pr">{{ currentUser?.status?.next_level_progress?.percent }}%</span>
              </div>

              <div class="profile-head-level-info" :class="{ active }">
                <div class="profile-head-level-info-line">
                  Следующий уровень: <i>{{ t('levels.' + currentUser?.status?.next_level) }}</i>
                </div>
                <div class="profile-head-level-info-line">
                  Бонус при достижении:
                  <i>{{
                    currentUser?.status?.next_level_bonus_text || currentUser?.status?.next_level_bonus + ' ₽'
                  }}</i>
                </div>
                <div class="profile-head-level-info-line">
                  Баллов до след. уровня:
                  <i>{{
                    (
                      (currentUser?.status?.next_level_progress?.goal -
                        currentUser?.status?.next_level_progress?.points) /
                      500
                    ).toFixed()
                  }}</i>
                </div>
              </div>

              <div class="profile-head-level-info-show-hide" @click="active = !active" :class="{ active }">
                <span class="more">Раскрыть</span>
                <span class="donmore">Скрыть</span>
              </div>
              <div class="profile-head-icon"><img src="/images/profile-head-icon1.svg" alt="" /></div>
            </div>

            <div class="profile-head-ballance">
              <div>Баланс</div>
              <div class="profile-head-ballance-num">
                {{ n(currentUser?.status?.balance || 0, currentUser?.currency || 'RUB') }}
              </div>

              <a @click="this.$router.push({ hash: '#topup' })" class="profile-head-pay-link">{{
                t('topup', 'Пополнить')
              }}</a>

              <div class="profile-head-icon"><img src="/images/profile-head-icon2.svg" alt="" /></div>
            </div>

            <div class="profile-head-back">
              <div>Возврат</div>
              <div class="profile-head-back-num">
                {{ n(currentUser?.status?.cashback || 0, currentUser?.currency || 'RUB') }}
              </div>
              <template v-if="currentUser?.status?.cashback !== 0">
                <a
                  href="#"
                  class="profile-head-pay-link"
                  @click.prevent="cashback"
                  v-if="currentUser?.status?.balance < 1"
                >
                  {{ cashbackInProgress ? 'Подождите...' : 'Вернуть' }}
                </a>
                <span class="more" v-else>
                  Возврат доступен, если баланс меньше {{ n(1, currentUser?.currency || 'RUB') }}
                </span>
              </template>

              <div class="profile-head-icon"><img src="/images/profile-head-icon3.svg" alt="" /></div>
            </div>
          </div>
        </div>

        <div class="profile-content">
          <div class="profile-tabs">
            <a
              @click="this.$router.push({ hash: '#cashdesk' })"
              :class="`profile-tabs-link ${
                this.isActiveNav(['#cashdesk', '#topup', '#getwin', '#promocod', '#bonus']) ? 'profile-tabs-active' : ''
              }`"
            >
              <img src="/images/profile-tab1.svg" alt="" /> {{ t('cashdesk') }}
            </a>
            <a
              @click="this.$router.push({ hash: '#profile' })"
              :class="`profile-tabs-link ${this.isActiveNav('#profile') ? 'profile-tabs-active' : ''}`"
            >
              <img src="/images/profile-tab2.svg" alt="" /> {{ t('cash.profile') }}
            </a>
            <a
              @click="this.$router.push({ hash: '#history' })"
              :class="`profile-tabs-link ${this.isActiveNav('#history') ? 'profile-tabs-active' : ''}`"
            >
              <img src="/images/profile-tab3.svg" alt="" /> {{ t('cash.history') }}
            </a>
            <a
              @click="this.$router.push({ hash: '#payments' })"
              :class="`profile-tabs-link ${this.isActiveNav('#payments') ? 'profile-tabs-active' : ''}`"
            >
              <img src="/images/profile-tab4.svg" alt="" /> {{ t('cash.payments') }}
            </a>
            <a
              @click="this.$router.push({ hash: '#mails' })"
              :class="`profile-tabs-link ${this.isActiveNav('#mails') ? 'profile-tabs-active' : ''}`"
            >
              <img src="/images/profile-tab5.svg" alt="" /> {{ t('cash.mail') }}
            </a>
          </div>

          <div class="profile-blocks">
            <div class="profile-block">
              <div
                class="tt-tabs tprofile"
                v-if="this.isActiveNav(['#cashdesk', '#topup', '#getwin', '#promocod', '#bonus'])"
              >
                <cashdesk />
              </div>
              <div class="tt-tabs tprofile" v-if="this.isActiveNav('#profile')">
                <profile />
              </div>
              <div class="tt-tabs tprofile" v-if="this.isActiveNav('#history')">
                <history />
              </div>
              <div class="tt-tabs tprofile" v-if="this.isActiveNav('#payments')">
                <payments />
              </div>
              <div class="tt-tabs tprofile" v-if="this.isActiveNav('#mails')">
                <mails />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="profile-wrap" v-else>Загрузка...</div>
    </div>
  </div>

  <a class="payredirect-close" @click="this.$router.push({ hash: '' })" />
</template>

<script>
import { useI18n } from 'vue-i18n'
import { CASHBACK, FETCH_CASHDESK_CONFIG } from '@/store/actions.type'
import CashdeskTopUp from '@/components/cashdesk/TopUp'
import CashdeskGetWin from '@/components/cashdesk/GetWin'
import { mapGetters } from 'vuex'

import Cashdesk from '@/views/Cashdesk/Cashdesk'
import History from '@/views/Cashdesk/History'
import Mails from '@/views/Cashdesk/Mails'
import Payments from '@/views/Cashdesk/Payments'
import Profile from '@/views/Cashdesk/Profile'
import Verification from '@/views/Cashdesk/Verification'
import UserLevelImg from '@/components/UserLevelImg.vue'
import { toast } from 'vue3-toastify'

export default {
  name: 'CashdeskFinance',
  components: {
    UserLevelImg,
    Profile,
    CashdeskTopUp,
    CashdeskGetWin,
    Cashdesk,
    History,
    Mails,
    Payments,
    Verification,
  },
  setup() {
    const { t, n } = useI18n()
    return { t, n }
  },
  data() {
    return {
      isLoading: false,
      active: false,
      cashbackInProgress: false,
    }
  },
  mounted() {
    if (!this.isAuthenticated) {
      this.$router.push({ name: 'login' })
      return
    }

    document.body.classList.add('p-overflow-hidden')

    this.isLoading = true
    this.$store.dispatch(FETCH_CASHDESK_CONFIG).then(() => {
      this.isLoading = false
    })
  },
  unmounted() {
    document.body.classList.remove('p-overflow-hidden')
  },
  computed: {
    ...mapGetters(['currentUser', 'isAuthenticated']),
  },
  methods: {
    isActiveNav(elem) {
      if (typeof elem === 'object') {
        return elem.includes(this.$route.hash)
      }
      return this.$route.hash === elem
    },
    cashback() {
      if (this.cashbackInProgress) {
        return
      }
      this.cashbackInProgress = true
      this.$store
        .dispatch(CASHBACK)
        .then(data => {
          toast.info(data.message)
        })
        .catch(res => {
          toast.error(res?.data?.err)
        })
        .finally(() => {
          this.cashbackInProgress = false
        })
    },
  },
}
</script>

<style scoped>
.modal-profile {
  max-width: 1404px;
  width: 100%;
  margin-top: 65px;
}

.modal-overlay {
  position: fixed;
}

.modal-wrap {
  overflow: auto;
  align-items: flex-start;
}

@media (min-width: 601px) {
  .modal-profile {
    margin-top: 90px;
  }
}

@media (min-width: 1251px) {
  .modal-profile {
    margin: 100px 0 50px;
  }
}

@media (min-width: 1401px) {
  .modal-profile {
    margin: 110px 0 50px;
  }
}

@media (max-width: 920px) {
  .profile-wrap {
    padding: 30px 16px 60px;
  }
}
</style>
