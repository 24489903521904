<template>
  <div class="profile-block">
    <div class="profile-data-fl">
      <PrivateDataForm />
      <PasswordForm />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { requiredValidation } from '@/utils'
import PrivateDataForm from '@/components/cashdesk/components/PrivateDataForm'
import PasswordForm from '@/components/cashdesk/components/PasswordForm'

export default {
  name: 'Profile',
  data() {
    return {
      phoneDisable: false,
      page: 0,
      tab: 0,
    }
  },
  components: {
    PasswordForm,
    PrivateDataForm,
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
  methods: {},
}
</script>

<style scoped></style>
