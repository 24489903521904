import i18n from '@/i18n.ts'
import store from '@/store/index.ts'
import { UPDATE_USER } from '@/store/actions.type.ts'

const LOCALE_KEY = 'locale'

export const locales = ['RU', 'EN']

function validate(locale) {
  locale = locale.toUpperCase().substring(0, 2)
  let found = false
  for (let l of locales) {
    if (l === locale) {
      found = true
      break
    }
  }
  if (!found) {
    return locales[0]
  }
  return locale
}

export function getDesiredLocale() {
  let locale = ''
  let userLocale = ''

  if (localStorage.getItem(LOCALE_KEY)) {
    locale = localStorage.getItem(LOCALE_KEY)
  }

  if (store.getters.isAuthenticated && store.getters.currentUser.profile && store.getters.currentUser.profile.lang) {
    // TODO store locale on the server
    // userLocale = store.getters.currentUser.profile.lang;
  }

  if (userLocale !== '') {
    locale = userLocale
  }

  return validate(locale)
  if (validLocale !== locale) {
    localStorage.setItem(LOCALE_KEY, validLocale)
  }

  if (i18n.global.locale.value !== validLocale.toLowerCase()) {
    i18n.global.locale.value = validLocale.toLowerCase()
  }

  return validLocale
}

export function setLocale(locale) {
  locale = validate(locale)

  if (store.getters.isAuthenticated) {
    store.dispatch(UPDATE_USER, { lang: locale })
  }

  localStorage.setItem(LOCALE_KEY, locale)
  if (i18n.global.locale.value !== locale.toLowerCase()) {
    i18n.global.locale.value = locale.toLowerCase()
  }
  return locale
}
