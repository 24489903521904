<template>
  <div class="profile-data-line-title">Документы:</div>

  <div class="profile-data-alert">Загрузите первую страницу паспорта</div>
  <a href="#" class="orange-transparent-btn">Загрузить</a>

  <div class="notification" style="padding-top: 5px">Файл не более 10 МБайт</div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { mapGetters } from 'vuex'

export default {
  name: 'VerificationDocuments',
  props: ['focused'],
}
</script>

<style scoped></style>
