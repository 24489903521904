import { FETCH_PAYSYSTEM_CONFIG, PAY } from './actions.type'
import { SET_PAYMENT_RESULT, SET_PENDING_PAYOUTS } from './mutations.type'

import ApiService from '@/common/api.service'

const state = {
  errors: null,
  paymentResult: {},
  pendingPayouts: [],
}

const getters = {
  paymentResult(state) {
    return state.paymentResult
  },
  pendingPayouts(state) {
    return state.pendingPayouts
  },
}

const actions = {
  [PAY](context, paymentData) {
    return new Promise(resolve => {
      paymentData.amount = parseFloat(paymentData.amount)
      ApiService.post('user/payments/in', { ...paymentData })
        .then(({ data }) => {
          context.commit(SET_PAYMENT_RESULT, data.data)
          resolve(data.data)
        })
        .catch(({ response }) => {
          // TODO process error
        })
    })
  },
  [FETCH_PAYSYSTEM_CONFIG](context, systemSlug) {
    return new Promise(resolve => {
      ApiService.post('user/payments/config/system', { slug: systemSlug })
        .then(({ data }) => {
          resolve(data.data)
        })
        .catch(({ response }) => {
          // TODO process error
          console.log(response)
        })
    })
  },
}

const mutations = {
  [SET_PAYMENT_RESULT](state, data) {
    state.paymentResult = data
  },
  [SET_PENDING_PAYOUTS](state, data) {
    state.pendingPayouts = data || []
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
}
