<template>
  <!--Успешно-->
  <div :class="`over uspeshno ${isActiveNav('#payment-success') ? 'active' : ''}`" @click.stop="closeDialog">
    <div class="uspeshno1">
      <div class="title">Успешно!</div>
      <div class="text">Ваш аккаунт успешно пополнен, удачи!</div>
      <div class="img"></div>
      <div class="close" @click="closeDialog"></div>
    </div>
  </div>

  <!--Ошибка-->
  <div :class="`over error ${isActiveNav('#payment-error') ? 'active' : ''}`" @click.stop="closeDialog">
    <div class="error-1">
      <div class="title">Ошибка!</div>
      <div class="text">Не удалось пополнить ваш аккаунт. Попробуйте другой метод.</div>
      <div class="img"></div>
      <div class="close" @click="closeDialog"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TopupModal',
  methods: {
    isActiveNav(elem) {
      if (typeof elem === 'object') {
        return elem.includes(this.$route.hash)
      }
      return this.$route.hash === elem
    },
    closeDialog() {
      this.$router.push({ hash: '' })
    },
  },
}
</script>
