<template>
  <the-header menu mobile-menu top-banner />

  <slot />
</template>

<script>
import TheHeader from '@/components/TheHeader'

export default {
  name: 'AuthLayout',
  components: {
    TheHeader,
  },
}
</script>
