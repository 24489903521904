<template>
  <div class="main-jackpot">
    <div class="main-jackpot-bg">
      <a href="/jackpots" class="main-jackpot-link">
        <span class="main-jackpot-name">Джекпот</span>
        <img src="/images/main-jackpot-logo2.svg" alt="" />
        <span class="main-jackpot-summ" v-if="jackpot?.jackpot">
          <vue-odometer :value="jackpot?.jackpot" format="( ddd).dd" />
          ₽
        </span>
      </a>

      <div class="main-jackpot-right">
        <div class="jackpot-slider">
          <swiper
            :slides-per-view="1"
            :spaceBetween="0"
            :modules="modules"
            :navigation="{ nextEl: '.slider-jackpot-next', prevEl: '.slider-jackpot-prev' }"
            :freeMode="true"
            :roundLengths="true"
            :loop="true"
            :loopedSlides="7"
            :loopAdditionalSlides="21"
            :breakpoints="{
              '500': {
                slidesPerView: 1,
              },
              '950': {
                slidesPerView: 3,
              },
              '1070': {
                slidesPerView: 4,
              },
              '1250': {
                slidesPerView: 5,
              },
              '1400': {
                slidesPerView: 6,
              },
            }"
          >
            <swiper-slide class="swiper-slide jackpot-slider-item" v-for="slug in games" :key="slug">
              <router-link
                :to="{ name: 'jackpots' }"
                class="jackpot-slider-item-link"
                :set="(game = gameBySlug(slug))"
                v-if="!isOnJackpotsPage"
              >
                <span class="jackpot-slider-item-image"><img :src="game?.icon" alt="" /></span>
                <span class="jackpot-slider-item-title"
                  ><span class="jackpot-slider-item-title-h">{{ game?.title }}</span
                  ><span class="jackpot-slider-item-title-b">Подробнее о джекпоте</span>
                </span>
              </router-link>
              <router-link
                :set="(game = gameBySlug(slug))"
                :to="{ name: isAuthenticated ? 'game' : 'demo', params: { game: game?.slug } }"
                class="jackpot-slider-item-link"
                v-else-if="gameBySlug(slug)"
              >
                <span class="jackpot-slider-item-image"><img :src="game?.icon" alt="" /></span>
                <span class="jackpot-slider-item-title"
                  ><span class="jackpot-slider-item-title-h">{{ game?.title }}</span>
                  <span class="jackpot-slider-item-title-b">{{ game?.title }}</span>
                </span>
              </router-link>
            </swiper-slide>
          </swiper>

          <div class="slider-jackpot-prev slider-arrow" />
          <div class="slider-jackpot-next slider-arrow slider-arrow-right" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation } from 'swiper/modules'
import { mapGetters } from 'vuex'
import Vue3Odometer from 'vue3-odometer'
import { FETCH_JACKPOT } from '@/store/actions.type'

export default {
  name: 'JackpotBlock',
  components: {
    Swiper,
    SwiperSlide,
    VueOdometer: Vue3Odometer,
  },
  mounted() {
    this.$store.dispatch(FETCH_JACKPOT)
  },
  setup() {
    const { t } = useI18n()

    return {
      modules: [Navigation],
      games: [
        'bookofra1',
        'monkey',
        'pharaonsring',
        'queencleopatra',
        'templeofgold',
        'lordof',
        'lordoftheoceanmagic',
        'bookofmaya',
        'bookoframagic',
        'bookofra2',
        'luckylady',
        'moneygame',
        'faust',
        'goldenark',
        'magicwindow',
        'redlady',
        'bookofstars',
        'dolphins',
      ],
      t,
    }
  },
  computed: {
    ...mapGetters(['jackpot', 'gameBySlug', 'isAuthenticated']),
    isOnJackpotsPage() {
      return this.$route.name === 'jackpots'
    },
  },
}
</script>

<style scoped>
@media (min-width: 1400px) {
  .swiper-slide.swiper-slide-active + .swiper-slide + .swiper-slide + .swiper-slide + .swiper-slide + .swiper-slide {
    opacity: 0.5;
  }
}
</style>
