<template>
  <div class="topbar-wrap" v-if="isShow">
    <div class="topbar">
      <img
        src="@/assets/components/top-banner/topbar-image2.png"
        class="topbar-image img2"
        width="99"
        height="52"
        alt="Subscribe"
      />
      <img
        src="@/assets/components/top-banner/topbar-image.png"
        class="topbar-image"
        width="134"
        height="52"
        alt="Subscribe"
      />
      <div class="topbar-label">
        Приветствуем Вас <b>на новом дизайне клуба МиллионЪ,</b> узнать о всех улучшениях можете
        <router-link :to="{ name: 'news', hash: '#big-update' }">тут</router-link>
        <img src="@/assets/components/top-banner/alert-icon.svg" class="alert-icon" alt="" />
      </div>
      <!--      <div class="topbar-text">или можете вернуться к старой версии сайта</div>-->
      <!--      <a href="#" class="topbar-btn"><span>К старой версии</span></a>-->
      <img src="@/assets/components/top-banner/topbar-image1.png" class="topbar-image img1" alt="Subscribe" />
    </div>
    <div class="topbar-close" @click="hide" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

const TOP_BANNER_NEW_DESIGN_KEY = 'top_banner_new_design_hidden'

export default {
  name: 'TopBanner',
  data() {
    return {
      show: true,
    }
  },
  computed: {
    ...mapGetters(['globalPopupOpen']),
    isShow() {
      return this.show && !window.localStorage.getItem(TOP_BANNER_NEW_DESIGN_KEY) && !this.globalPopupOpen
    },
  },
  methods: {
    hide() {
      this.show = false
      window.localStorage.setItem(TOP_BANNER_NEW_DESIGN_KEY, 'hidden')
    },
  },
}
</script>

<style scoped>
.topbar-wrap {
  padding: 0 70px;
  background: linear-gradient(180deg, #ef9227 0%, #f9cf22 100%);
  position: relative;
  z-index: 2;
}

.topbar {
  display: flex;
  height: 52px;
  justify-content: center;
  align-items: center;
}

.alert-icon {
  margin-left: 20px;
  vertical-align: middle;
}

.topbar-label {
  font-size: 15px;
  color: #222;
  margin-right: 20px;
}

.topbar-label a {
  color: #000;
  border-bottom: 2px solid #d63c13;
}

.topbar-btn {
  margin-left: 25px;
  display: flex;
  height: 32px;
  line-height: 32px;
  background: linear-gradient(180deg, #a6d007 0%, #008d00 100%);
  border-radius: 5px;
  padding: 0 30px 0 15px;
  align-items: center;
  font-size: 13px;
  color: #fff;
  font-weight: 500;
  min-width: 130px;
}

.topbar-btn:before {
  content: '';
  margin-right: 10px;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M7 0C10.866 0 14 3.13401 14 7C14 10.866 10.866 14 7 14C4.4052 14 2.14212 12.5871 0.933099 10.4897L3.39745 9.07214C4.1158 10.3181 5.45852 11.1588 7 11.1588C9.29685 11.1588 11.1588 9.29685 11.1588 7C11.1588 4.70315 9.29685 2.84118 7 2.84118C6.01548 2.84118 5.11313 3.1856 4.40149 3.75805L6.2395 5.06543L0 7.14611V0.625485L2.03198 2.07129C3.30022 0.792773 5.05688 0 7 0Z' fill='white'/%3e%3c/svg%3e ");
  width: 18px;
  height: 18px;
  background-repeat: no-repeat;
  background-position: center;
}

.topbar-text {
  font-size: 15px;
  color: #222;
}

.topbar-image {
  margin-left: 20px;
}

.topbar-close {
  width: 70px;
  height: 100%;
  cursor: pointer;
  background: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.09953 6.00009L11.7722 1.32729C12.0763 1.02334 12.0763 0.531906 11.7722 0.22796C11.4682 -0.0759865 10.9768 -0.0759865 10.6729 0.22796L6.00005 4.90076L1.3274 0.22796C1.02331 -0.0759865 0.532013 -0.0759865 0.228066 0.22796C-0.0760221 0.531906 -0.0760221 1.02334 0.228066 1.32729L4.90072 6.00009L0.228066 10.6729C-0.0760221 10.9768 -0.0760221 11.4683 0.228066 11.7722C0.379541 11.9238 0.578708 12 0.777732 12C0.976756 12 1.17578 11.9238 1.3274 11.7722L6.00005 7.09942L10.6729 11.7722C10.8245 11.9238 11.0235 12 11.2225 12C11.4215 12 11.6206 11.9238 11.7722 11.7722C12.0763 11.4683 12.0763 10.9768 11.7722 10.6729L7.09953 6.00009Z' fill='%23222222'/%3E%3C/svg%3E%0A")
    center center no-repeat;
  position: absolute;
  right: 0;
  top: 0;
}

.topbar-close:hover {
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.09953 6.00009L11.7722 1.32729C12.0763 1.02334 12.0763 0.531906 11.7722 0.22796C11.4682 -0.0759865 10.9768 -0.0759865 10.6729 0.22796L6.00005 4.90076L1.3274 0.22796C1.02331 -0.0759865 0.532013 -0.0759865 0.228066 0.22796C-0.0760221 0.531906 -0.0760221 1.02334 0.228066 1.32729L4.90072 6.00009L0.228066 10.6729C-0.0760221 10.9768 -0.0760221 11.4683 0.228066 11.7722C0.379541 11.9238 0.578708 12 0.777732 12C0.976756 12 1.17578 11.9238 1.3274 11.7722L6.00005 7.09942L10.6729 11.7722C10.8245 11.9238 11.0235 12 11.2225 12C11.4215 12 11.6206 11.9238 11.7722 11.7722C12.0763 11.4683 12.0763 10.9768 11.7722 10.6729L7.09953 6.00009Z' fill='%23fff'/%3E%3C/svg%3E%0A");
}

@media (max-width: 1000px) {
  .topbar-wrap {
    padding: 5px 40px 5px 10px;
    flex-wrap: wrap;
  }
}
</style>
