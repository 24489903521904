<template>
  <Transition>
    <div
      style="position: absolute; width: 100%; height: 100%; background-color: #041b04; z-index: 10000"
      v-if="loading"
    >
      <object class="preloader-logo-site" type="image/svg+xml" data="/images/mln-logo-preloader-site.svg"></object>
    </div>
  </Transition>
  <component :is="layout">
    <router-view />
  </component>

  <div class="footer-up footer-up2" @click="this.scrollToTop" v-if="this.showUpBtn && !globalPopupOpen" />

  <div
    :class="`bottom-fly-line ${this.showUpBtn ? 'bottom-fly-line-show' : ''}`"
    v-if="!isAuthenticated && !globalPopupOpen"
  >
    <div class="bottom-fly-line-w">
      <div class="bottom-fly-bonus">
        <object type="image/svg+xml" data="/images/gift-ani.svg" />Бонус 250% +
        <span style="color: #a6d007">30 FS</span>
      </div>
      <router-link :to="{ name: 'register' }" class="bottom-fly-reg"> Регистрация </router-link>
    </div>
  </div>
  <div
    class="head-line-r head-line-r-login hidepclogin"
    :class="{ active1: active1, active: active }"
    v-else-if="!globalPopupOpen"
  >
    <div class="fon" @click=";(active1 = false), (active = false)"></div>
    <div class="head-mob" :class="{ active1: active1 }">
      <div class="login-user" @click=";(active1 = !active1), (active = false)">
        <div class="login-user-image">
          <UserLevelImg :level="currentUser?.status.cur_level" style="max-height: 40px" v-if="currentUser" />
        </div>
        <div class="login-user-content">
          <div class="login-user-title">
            <a> {{ currentUser?.profile?.display_name || currentUser?.username }} </a>
            <div class="hidepclogin-ballance">
              <a class="hidepclogin-ballance"
                >{{ n(currentUser?.status.balance || 0, currentUser?.currency || 'RUB') }}
              </a>
            </div>
            <span style="color: #81c105; display: none">{{ t('levels.' + currentUser?.status?.cur_level) }}</span>
          </div>
        </div>
      </div>

      <div class="head-game-ico" @click=";(active = !active), (active1 = false)"></div>

      <a @click="this.$router.push({ hash: '#topup' })" class="orange-btn-small head-login-kassa">
        {{ t('cashdesk', 'Касса') }}
      </a>
      <div @click="this.$router.push({ hash: '#bonus' })" :class="`open-present ${bonusesCount > 0 ? 'active' : ''}`">
        <object type="image/svg+xml" data="/images/open-present.svg" />
      </div>
    </div>

    <div class="head-game-block" :class="{ active: active }">
      <div>
        <a href="/favourites" class="head-game-1">Любимые</a>
        <a href="/category/new" class="head-game-2">Новинки</a>
        <a href="/vendor" class="head-game-3">Провайдеры</a>
        <a href="/topics" class="head-game-4">Темы слотов</a>
        <a href="/category/roulette" class="head-game-5">Рулетка</a>
        <a href="/topic/blackjack" class="head-game-6">Блекджек</a>
      </div>
    </div>

    <div class="head-game-block-2" :class="{ active1: active1 }">
      <div class="block">
        <div class="block-1">
          <div class="profile-head-level-t">
            <div class="profile-head-level-name">{{ t('levels.' + currentUser?.status?.cur_level) }}</div>
            <div class="profile-head-level-progress">
              <div
                class="profile-head-level-progress-current"
                :style="`width: ${currentUser?.status?.next_level_progress?.percent}%`"
              ></div>
            </div>
            <span data-v-0584be89="" class="profile-head-level-pr"
              >{{ currentUser?.status?.next_level_progress?.percent }}%</span
            >
          </div>
          <a href="#" class="out" @click.prevent="logout">Выйти</a>
        </div>
        <div class="block-2">
          Баланс <span>{{ n(currentUser?.status?.balance || 0, currentUser?.currency || 'RUB') }}</span>
        </div>
        <div class="block-3">
          Возврат <span>{{ n(currentUser?.status?.cashback || 0, currentUser?.currency || 'RUB') }}</span>
          <a href="#" class="back-cash" @click.prevent="cashback" v-if="currentUser?.status?.balance < 1">
            {{ cashbackInProgress ? 'Подождите...' : 'Вернуть' }}
          </a>
          <span class="more" v-else>
            Возврат доступен, если баланс меньше {{ n(1, currentUser?.currency || 'RUB') }}
          </span>
        </div>
        <div class="block-4">
          <a @click="this.$router.push({ hash: '#topup' })" class="add-cashe">{{ t('topup', 'Пополнить') }}</a>
          <a @click="this.$router.push({ hash: '#profile' })" class="profil">Профиль</a>
        </div>
      </div>
    </div>
  </div>

  <cashdesk v-if="openCashdesk" />
</template>

<script>
import MobileMenu from '@/components/MobileMenu'
import { FETCH_GAMES, FETCH_WINNERS, FETCH_TOURNAMENTS, FETCH_FAVOURITES, CASHBACK, LOGOUT } from '@/store/actions.type'
import { mapGetters } from 'vuex'
import { useI18n } from 'vue-i18n'
import Cashdesk from '@/views/Cashdesk'
import { Talkme } from '@/components/talkme'
import { toast } from 'vue3-toastify'
import UserLevelImg from '@/components/UserLevelImg.vue'

export default {
  name: 'App',
  components: {
    UserLevelImg,
    MobileMenu,
    Cashdesk,
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'currentUser', 'globalPopupOpen']),
    layout() {
      return this.$route.meta.layout || 'default-layout'
    },
    openCashdesk() {
      return [
        '#cashdesk',
        '#bonus',
        '#mails',
        '#profile',
        '#payments',
        '#history',
        '#getwin',
        '#promocod',
        '#topup',
      ].includes(this.$route.hash)
    },
    bonusesCount() {
      const fs = this.currentUser?.status?.freespins ? this.currentUser?.status?.freespins.length : 0
      const bonuses = this.currentUser?.status?.bonuses ? this.currentUser?.status?.bonuses.length : 0
      return fs + bonuses
    },
  },
  data() {
    return {
      showUpBtn: false,
      active: false,
      active1: false,
      cashbackInProgress: false,
      loading: true,
    }
  },
  setup() {
    const { t, n } = useI18n()
    return { t, n }
  },
  created() {
    this.$store.dispatch(FETCH_GAMES)
    this.$store.dispatch(FETCH_WINNERS)
    this.$store.dispatch(FETCH_TOURNAMENTS)
    if (this.isAuthenticated) {
      this.$store.dispatch(FETCH_FAVOURITES)
    }
  },
  mounted() {
    Talkme.init('millionb.com', this.currentUser)

    window.addEventListener('scroll', this.changeShowBtn, { passive: true })
    this.loading = false
  },
  methods: {
    changeShowBtn() {
      if (window.pageYOffset > 300) {
        this.showUpBtn = true
      } else {
        this.showUpBtn = false
      }
      const topBar = document.querySelector('.topbar-wrap')
      const elem = document.querySelector('.head-line')
      const slider = document.querySelector('.slider-block')
      let topBarHeight = 0
      if (topBar) {
        topBarHeight = topBar.getBoundingClientRect().height
      }

      if (window.pageYOffset > topBarHeight) {
        elem.classList.add('head-line-fixed')
        if (slider) {
          slider.style.marginTop = '80px'
        }
      } else {
        elem.classList.remove('head-line-fixed')
        if (slider) {
          slider.style.marginTop = '0'
        }
      }
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    },
    cashback() {
      if (this.cashbackInProgress) {
        return
      }
      this.cashbackInProgress = true
      this.$store
        .dispatch(CASHBACK)
        .then(data => {
          toast.info(data.message)
        })
        .catch(res => {
          toast.error(res?.data?.err)
        })
        .finally(() => {
          this.cashbackInProgress = false
        })
    },
    logout() {
      this.$store.dispatch(LOGOUT).then(() => {
        this.$router.push({ name: 'home' })
      })
    },
  },
}
</script>

<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
