<template>
  <!--Почта подтверждена-->
  <div
    :class="`over regist-add mail-good ${isActiveNav('#email-confirmed') ? 'active' : ''}`"
    @click.stop="closeDialog"
  >
    <div class="regist-1">
      <div class="logo-user"><img src="/images/regist-add.svg" alt="" /></div>
      <div class="title">Ваша почта <span>подтверждена</span></div>
      <div class="text">
        Спасибо за подтверждение почты &mdash; теперь Вам доступны все бонусные программы игрового клуба Миллионъ.
      </div>
      <div class="btn">
        <a href="#cashdesk" class="btn-1">Перейти в кассу</a>
      </div>
      <div class="btn">
        <a href="#" class="btn-2" @click.stop="closeDialog">Хорошо</a>
      </div>
      <div class="close" @click.stop="closeDialog"></div>
    </div>
  </div>

  <!--Отписались от рассылки-->
  <div
    :class="`over regist-add mail-good ${isActiveNav('#email-unsubscribed') ? 'active' : ''}`"
    @click.stop="closeDialog"
  >
    <div class="regist-1">
      <div class="logo-user"><img src="/images/regist-add-1.svg" alt="" /></div>
      <div class="title">Вы отписались <span>от рассылки</span></div>
      <div class="text">Спасибо, что были с нами. Надеемся наши письма были полезными для Вас.</div>
      <div class="btn">
        <a href="#" class="btn-2" @click.stop="closeDialog">Закрыть</a>
      </div>
      <div class="close" @click.stop="closeDialog"></div>
    </div>
  </div>

  <!--Подтвердить Регистрацию-->
  <div class="over regist-add">
    <div class="regist-1">
      <div class="logo-user"><img src="/images/regist-add.svg" alt="" /></div>
      <div class="title">Подтвердите <span>регистрацию</span></div>
      <div class="text">
        На e-mail <span class="color">richalexnew@ya.ru</span> отправлено письмо. Перейдите по ссылке из письма, чтобы
        подтвердить почту и получить специальные бонусы.
      </div>
      <div class="btn">
        <a href="#" class="btn-1">Понятно</a>
      </div>
      <div class="text-footer">
        <p>Если писем нет во <span class="color">Входящих</span>, проверьте <span class="color">Спам</span>.</p>
      </div>
      <div class="close"></div>
    </div>
  </div>
</template>

<style scoped></style>

<script>
import { Autoplay, Pagination, Navigation } from 'swiper/modules'
import { getDesiredLocale, setLocale } from '@/common/locale'
import { useI18n } from 'vue-i18n'
import UserLevelImg from '@/components/UserLevelImg.vue'
import { Swiper, SwiperSlide } from 'swiper/vue'

export default {
  name: 'EmailModals',
  data() {
    return {
      openLang: false,
      lang: 'RU',
    }
  },
  components: {
    UserLevelImg,
    Swiper,
    SwiperSlide,
  },
  setup() {
    const { t } = useI18n()

    return {
      modules: [Autoplay, Pagination, Navigation],
      t,
    }
  },
  created() {
    this.lang = getDesiredLocale()
  },
  methods: {
    isActiveNav(elem) {
      if (typeof elem === 'object') {
        return elem.includes(this.$route.hash)
      }
      return this.$route.hash === elem
    },
    closeDialog() {
      this.$router.push({ hash: '' })
    },
  },
}
</script>
