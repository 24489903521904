<template>
  <div class="slot-slider-line">
    <div class="slot-slider-title">
      <span>{{ string }}</span>
      <router-link
        :to="{
          name: 'home',
          hash: '#home',
        }"
        >Все
      </router-link>
    </div>

    <div class="slider-games" v-if="games?.length">
      <div v-for="game in games" :key="game.id">
        <game-block :game="game" />
      </div>
    </div>
    <div class="slider-games" v-else>
      <div>Список пуст</div>
    </div>
  </div>
</template>

<script>
import GameBlock from '@/components/GameBlock'
import { useI18n } from 'vue-i18n'
import { ref } from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue'

export default {
  name: 'GameListNoSlider',
  props: {
    string: {},
    games: Array,
  },
  setup() {
    const { t } = useI18n()

    const slider = ref(null)

    const onSwiper = swiper => {
      slider.value = swiper
    }

    return { t, onSwiper, slider }
  },
  components: {
    Swiper,
    SwiperSlide,
    GameBlock,
  },
}
</script>

<style scoped>
.slider-games {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -6px;
}
.slider-games > div {
  margin: 0 6px;
}
</style>
