<template>
  <div class="modal-wrap">
    <div class="modal-overlay" @click="this.$emit('closeModal')" />

    <div class="modal-content">
      <div class="payredirect">
        <div class="payment-success">Спасибо<br />Ваш счёт успешно пополнен!</div>

        <a class="payredirect-close" @click="this.$emit('closeModal')"></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SuccessPayment',
  computed: {},
  mounted() {
    document.body.classList.add('p-overflow-hidden')
  },
  unmounted() {
    document.body.classList.remove('p-overflow-hidden')
  },
}
</script>

<style scoped lang="scss"></style>
