<template>
  <div class="phone-input">
    <vue-tel-input
      v-model="phone"
      @validate="phoneValidate"
      mode="international"
      :inputOptions="{ placeholder: 'Введите номер', showDialCode: true }"
      :preferredCountries="['RU', 'UA', 'KZ']"
      :dropdownOptions="{
        disabled: false,
        showDialCodeInSelection: true,
        showFlags: true,
        showDialCodeInList: true,
        showSearchBox: false,
        tabindex: 0,
      }"
      class="input-standart"
    />
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { VueTelInput } from 'vue-tel-input'

export default {
  name: 'Phone',
  components: { VueTelInput },
  props: ['setValue', 'value'],
  setup() {
    const { t } = useI18n()
    return { t }
  },
  data() {
    return {
      phone: null,
      isValidPhoneNumber: false,
    }
  },
  methods: {
    phoneValidate(phoneObject) {
      this.isValidPhoneNumber = phoneObject.valid
    },
  },
  watch: {
    phone(data) {
      if (this.isValidPhoneNumber) {
        this.setValue(data)
      } else {
        this.setValue('')
      }
    },
  },
}
</script>

<style scoped></style>
