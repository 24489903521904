<template>
  <div class="phone-verification">
    <div class="phone profile-data-line">
      <div class="profile-data-line-title">Номер телефона:</div>
      <div :class="`${isActivated ? 'verif-chek-done' : ''}`" v-if="!preDefinedPhone && !isActivated">
        <vue-tel-input
          v-model="phoneNumber"
          @validate="phoneValidate"
          mode="international"
          :inputOptions="{ placeholder: 'Введите номер', showDialCode: true }"
          :preferredCountries="['RU', 'UA', 'KZ']"
          :dropdownOptions="{
            disabled: false,
            showDialCodeInSelection: true,
            showFlags: true,
            showDialCodeInList: true,
            showSearchBox: false,
            tabindex: 0,
          }"
          :disabled="fatalError"
          class="input-standart"
        />
        <div class="profile-data-alert" v-if="mustShowHint">
          Пожалуйста, введите корректный номер телефона в международном формате.
        </div>
      </div>
      <input type="text" v-model="phoneNumber" class="input-standart" readonly v-else />

      <template v-if="!isActivated">
        <button
          class="orange-btn"
          :disabled="!phoneNumber || loading || !isValidPhoneNumber || fatalError"
          @click="this.sendCode"
          v-if="!fatalError"
        >
          {{ phoneCodeSent ? 'Отправить код повторно' : 'Подтвердить номер' }}
        </button>
        <a class="btn" href="#" v-if="phoneNumber && phoneCodeSent" @click.prevent="changeNumber">Изменить номер</a>
      </template>
    </div>
    <div class="code profile-data-line" v-if="phoneCodeSent && !confirmed && !fatalError">
      <div class="profile-data-line-title">Проверочный код из SMS:</div>
      <v-otp-input
        ref="otpInput"
        v-model:value="code"
        :num-inputs="4"
        input-classes="otp input-standart"
        separator="-"
        should-auto-focus="true"
        @on-complete="codeCompleted"
      />
    </div>
  </div>

  <div class="profile-data-alert" v-if="errorMessage">{{ errorMessage }}</div>
  <div class="profile-data-alert" v-if="secondsLeft">Следующая попытка через: {{ secondsLeft }} сек</div>

  <div class="profile-data-alert" v-if="wrongCode">
    Пожалуйста, введите верный код для верификации вашего номера телефона. В коде содержится ошибка.
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { mapGetters } from 'vuex'
import { SEND_PHONE_VERIFICATION_CODE, VERIFY_PHONE_CODE } from '@/store/actions.type'
import { toast } from 'vue3-toastify'
import { Field as VField } from 'vee-validate'
import { VueTelInput } from 'vue-tel-input'
import VOtpInput from 'vue3-otp-input'

export default {
  name: 'VerificationPhone',
  components: { VField, VueTelInput, VOtpInput },
  props: ['focused', 'preDefinedPhone'],
  emits: ['confirmed', 'failed'],
  data() {
    return {
      phoneNumber: this.preDefinedPhone ? '+' + this.preDefinedPhone : '',
      isValidPhoneNumber: !!this.preDefinedPhone,
      code: '',
      isCompleteCode: false,
      phoneCodeSent: false,
      wrongCode: false,
      fatalError: false,
      loading: false,
      errorMessage: null,
      secondsLeft: null,
      confirmed: false,
    }
  },
  setup() {
    const { t } = useI18n()
    return { t }
  },
  computed: {
    ...mapGetters(['currentUser']),
    isActivated() {
      return !!this.currentUser?.confirmed || this.confirmed
    },
    mustShowHint() {
      if (this.phoneNumber.length < 5) {
        return false
      }
      return !this.isValidPhoneNumber
    },
  },
  methods: {
    focusOnPhone() {
      this.$refs.phoneInput.focus()
    },
    cleanupErrors() {
      this.errorMessage = null
      this.wrongCode = false
      this.secondsLeft = null
      this.fatalError = false
    },
    sendCode() {
      this.loading = true
      this.phoneCodeSent = false
      this.cleanupErrors()

      this.$store
        .dispatch(SEND_PHONE_VERIFICATION_CODE, { phone: this.phoneNumber })
        .then(data => {
          if (data.success) {
            this.phoneCodeSent = true
            return
          }
          this.errorMessage = data.message
          this.secondsLeft = data.secondsLeft
        })
        .catch(res => {
          toast.error(res?.data?.err)
        })
        .finally(() => {
          this.loading = false
        })
    },
    verifyCode() {
      this.loading = true
      this.cleanupErrors()

      this.$store
        .dispatch(VERIFY_PHONE_CODE, { code: this.code })
        .then(data => {
          this.wrongCode = !data.success
          if (this.wrongCode) {
            this.$refs.otpInput?.clearInput()
            this.errorMessage = data.message
            if (this.errorMessage) {
              this.wrongCode = false
              this.fatalError = true
            }
            this.$emit('failed')
          } else {
            this.confirmed = true
            this.cleanupErrors()
            this.$emit('confirmed')
          }
        })
        .catch(res => {
          toast.error(res?.data?.err)
        })
        .finally(() => {
          this.loading = false
        })
    },
    changeNumber() {
      this.phoneNumber = ''
      this.phoneCodeSent = false
      this.cleanupErrors()
    },
    phoneValidate(phoneObject) {
      this.isValidPhoneNumber = phoneObject.valid
    },
    codeCompleted(value) {
      this.isCompleteCode = true
      this.verifyCode()
    },
  },
  mounted() {
    if (this.focused) {
      this.focusOnPhone()
    }
    this.phoneNumber = this.currentUser?.phone
  },
}
</script>

<style scoped>
.orange-btn {
  border: none;
  cursor: pointer;
  margin: 10px 0;
}
.orange-btn:disabled {
  color: #333;
}
.btn {
  color: #999;
  font-size: 14px;
  text-decoration: underline;
}
.vue-tel-input {
  width: 100%;
}
.vue-tel-input.input-standart {
  padding: 0;
}
.otp-input-container {
  width: fit-content;
  margin: 0 auto;
}
</style>
