<template>
  <div class="profile-data-line-fl">
    <div class="profile-data-line-title">Верификация почты:</div>
    <div class="verif-chek verif-chek-done">
      <input type="text" name="" class="input-standart" placeholder="введите email" />
    </div>
    <a href="#" class="orange-btn">Отправить</a>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { mapGetters } from 'vuex'

export default {
  name: 'VerificationEmail',
  props: ['focused'],
  data() {
    return {
      phoneNumber: '',
      phoneCodeSent: false,
    }
  },
  setup() {
    const { t } = useI18n()
    return { t }
  },
  computed: {
    ...mapGetters(['currentUser']),
    isActivated() {
      return !!this.currentUser?.confirmed
    },
  },
  methods: {
    focusOnPhone() {
      this.$refs.phoneInput.focus()
    },
    sendCode() {
      this.phoneCodeSent = true
    },
    verifyCode() {
      // this.phoneCodeSent = true
    },
  },
  mounted() {
    if (this.focused) {
      this.focusOnPhone()
    }
  },
}
</script>

<style scoped></style>
