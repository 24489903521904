<template>
  <topup-modal />
  <register-success-modal />
  <new-level-modal />
  <email-modals />
  <migrated-modal />
</template>

<script>
import TopupModal from '@/components/modals/Topup.vue'
import RegisterSuccessModal from '@/components/modals/RegisterSuccess.vue'
import EmailModals from '@/components/modals/Email.vue'
import NewLevelModal from '@/components/modals/NewLevel.vue'
import MigratedModal from '@/components/modals/Migrated.vue'

export default {
  name: 'AuthenticatedModals',
  components: { NewLevelModal, EmailModals, RegisterSuccessModal, TopupModal, MigratedModal },
}
</script>
