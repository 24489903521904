<template>
  <div class="modal-wrap">
    <div class="modal-overlay" @click="this.$emit('closeModal')" />

    <div class="modal-content">
      <div class="payredirect">
        <div class="payredirect-status-nav">
          <a class="payredirect-back" @click="this.$emit('closeModal')">Вернуться назад</a>
          Статус заявки:
          <span style="color: #eebd11" v-if="!this.paid">Ожидает оплаты</span>
          <span style="color: #81c105" v-else>Оплачено</span>
        </div>
        <div class="payredirect-status-nav2">
          Отправьте <span style="color: #81c105">{{ this.paymentData.paymentData.amount }} ₽</span> на указанный номер
          карты, оплатив комиссию за перевод средств:
        </div>
        <div class="payredirect-status-fl">
          <div class="payredirect-status-num">
            <div class="payredirect-status-num-item">{{ this.paymentData.paymentData.wallet }}</div>
            Реквизиты для перевода
          </div>

          <a
            class="payredirect-status-copy"
            @click="this.copyToClipboard(this.paymentData.paymentData.wallet, () => (this.copied = true))"
            v-if="!this.copied"
            >Скопировать</a
          >
          <div class="payredirect-status-copy-done" v-else>Скопировано</div>

          <vue-countdown
            :time="this.paymentData.paymentData.ttl * 1000"
            v-slot="{ minutes, seconds }"
            v-if="!this.paid"
          >
            <div class="payredirect-status-timer timeTo timeTo-white" style="font-size: 18px">
              <div class="first">
                {{ minutes }}
              </div>
              <span>:</span>
              <div>
                {{ seconds }}
              </div>
            </div>
          </vue-countdown>
        </div>

        <ul class="dtext-ul2">
          <li>
            Переведите средства вручную на указанный номер карты. В случае отсутствия доступа к онлайн-банкингу
            воспользуйтесь кнопкой "Онлайн перевод", сумма перевода должна быть равна сумме в текущей заявке.
          </li>
          <li>После перевода ожидайте зачисление средств на баланс в течение 5 минут.</li>
          <li>Об успешном зачислении средств Вас уведомит "Статус заявки".</li>
          <li>Совершать повторные переводы на карту, без создания новой заявки в кассе - строго запрещено.</li>
          <li>
            <span style="color: #eebd11">Внимание!</span> Не закрывайте и не обновляйте эту страницу до момент
            зачисление платежа.
          </li>
        </ul>

        <a class="payredirect-submit">Перевести онлайн</a>

        <a class="payredirect-close" @click="this.$emit('closeModal')"></a>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import VueCountdown from '@chenfengyuan/vue-countdown'
import { FETCH_PAYMENT_STATUS } from '@/store/actions.type'
import { copyToClipboard } from '@/utils'

export default {
  name: 'AwaitingPayment',
  props: ['paymentData'],
  components: { VueCountdown },
  setup() {
    const { t, n } = useI18n()
    return { t, n }
  },
  data() {
    return {
      interval: null,
      paid: false,
      copied: false,
    }
  },
  computed: {},
  mounted() {
    this.interval = setInterval(() => {
      this.$store.dispatch(FETCH_PAYMENT_STATUS, { paymentId: this.paymentData.paymentId }).then(response => {
        if (response?.data?.status === 'completed') {
          clearInterval(this.interval)
          this.paid = true
        }
      })
    }, 3000)
  },
  unmounted() {
    clearInterval(this.interval)
  },
  methods: {
    copyToClipboard,
  },
}
</script>

<style scoped lang="scss"></style>
