<template>
  <div class="site-info">
    <div class="site-info-title">
      <h1>· Официальный сайт игрового клуба Миллионъ ·</h1>
    </div>
    <div class="site-info-text">
      Если вы хотите испытать удачу в по-настоящему честном казино – Добро пожаловать на наш сайт! У нас вы можете не
      только приятно провести время, но и заработать неплохие деньги. Онлайн казино Миллион – это большой выбор
      разнообразных игр на любой вкус. Если вы азартный человек и не представляете своей жизни без игрового автомата, то
      вы пришли как раз по адресу, в нашем интернет казино вы сможете играть в любое время бесплатно или наоборот делать
      крупные ставки и выигрывать. В нашем казино все игры доступны без скачивания или каких-либо других дополнительных
      программ. Всем пользователям, которые бы хотели ознакомится с многообразием наших игр, доступны их демо-версии,
      даже без регистрации.
      <br /><br />
      Игровой клуб Миллион предлагает всем любителям азартных игр испытать свою удачу прямо сейчас! Начать играть в
      нашем казино очень просто - для это необходимо зарегистрироваться на нашем сайте и сразу после этого вам будут
      доступны все услуги, которые предоставляют наши игровые автоматы Миллион. Процедура регистрация не займет у вас
      много времени, и после нее вы становитесь полноправным участником нашего клуба. Если вы решили играть на деньги,
      то для начала вам необходимо будет пополнить свой персональный счет, сделать это будет невероятно просто – ввод и
      вывод денег производится с помощью популярных электронных систем, и вы сможете самостоятельно выбрать самый
      удобный для вас способ.
      <br /><br />
      Прежде чем делать ставки вы можете ознакомится с правилами игры и набрать немного опыта, сыграв бесплатно. Если вы
      разберетесь в стратегией игры, то выиграть будет гораздо проще, и таким образом вместе с удовольствием от самого
      игрового процесса вы получите еще и определенную сумму денег. Помимо выигрыша наши клиенты смогут получить до 50
      процентов от суммы проигрыша реальными деньгами, кроме того, самых активных игроков будут ждать приятные бонусы и
      сюрпризы. Игровые автоматы Миллион – самый простой и удобный способ выиграть деньги.
      <br /><br />
      Из реальных игорных заведений не всегда можно безопасно вынести выигрыш, наш клуб Миллион позволит вам без риска
      забрать свои призовые деньги. Безопасность стала одной из основных причин перехода игроков от реальных казино к
      виртуальным. Если вы решили стать нашим постоянным клиентом, или просто попробовать сыграть в нашем казино – мы
      всегда будем рады вас видеть, мы желаем вам удачи и легких побед!
    </div>
  </div>
</template>

<script>
export default {
  name: 'SiteInfo',
}
</script>

<style scoped></style>
