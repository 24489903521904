const levelDescriptions = [
  {
    level: 1,
    points: 0,
    cashback: 0,
    bonus: '250% + 30 FS',
    wager: 10,
    withdrawLimit: '10 000 ₽',
    hasFreespins: false,
  },
  {
    level: 2,
    points: 10,
    cashback: 3,
    bonus: '100 ₽',
    wager: 0,
    withdrawLimit: '15 000 ₽',
    hasFreespins: false,
  },
  {
    level: 3,
    points: 40,
    cashback: 5,
    bonus: '400 ₽',
    wager: 0,
    withdrawLimit: '20 000 ₽',
    hasFreespins: false,
  },
  {
    level: 4,
    points: 210,
    cashback: 10,
    bonus: '1 500 ₽',
    wager: 10,
    withdrawLimit: '30 000 ₽',
    hasFreespins: true,
  },
  {
    level: 5,
    points: 540,
    cashback: 15,
    bonus: '3 000 ₽',
    wager: 0,
    withdrawLimit: '40 000 ₽',
    hasFreespins: true,
  },
  {
    level: 6,
    points: 2000,
    cashback: 20,
    bonus: '10 000 ₽',
    wager: 0,
    withdrawLimit: '50 000 ₽',
    hasFreespins: true,
  },
  {
    level: 7,
    points: 4400,
    cashback: 25,
    bonus: '20 000 ₽',
    wager: 0,
    withdrawLimit: '60 000 ₽',
    hasFreespins: true,
  },
  {
    level: 8,
    points: 9000,
    cashback: 30,
    bonus: '30 000 ₽',
    wager: 0,
    withdrawLimit: '65 000 ₽',
    hasFreespins: true,
  },
  {
    level: 9,
    points: 9600,
    cashback: 32,
    bonus: '30 000 ₽',
    wager: 0,
    withdrawLimit: '65 000 ₽',
    hasFreespins: true,
  },
  {
    level: 10,
    points: 10200,
    cashback: 34,
    bonus: '30 000 ₽',
    wager: 0,
    withdrawLimit: '65 000 ₽',
    hasFreespins: true,
  },
  {
    level: 11,
    points: 12000,
    cashback: 36,
    bonus: '30 000 ₽',
    wager: 0,
    withdrawLimit: '65 000 ₽',
    hasFreespins: true,
  },
  {
    level: 12,
    points: 12000,
    cashback: 38,
    bonus: '30 000 ₽',
    wager: 0,
    withdrawLimit: '70 000 ₽',
    hasFreespins: true,
  },
  {
    level: 13,
    points: 12000,
    cashback: 40,
    bonus: '30 000 ₽',
    wager: 0,
    withdrawLimit: '70 000 ₽',
    hasFreespins: true,
  },
  {
    level: 14,
    points: 18000,
    cashback: 42,
    bonus: '30 000 ₽',
    wager: 0,
    withdrawLimit: '70 000 ₽',
    hasFreespins: true,
  },
  {
    level: 15,
    points: 18000,
    cashback: 44,
    bonus: '30 000 ₽',
    wager: 0,
    withdrawLimit: '75 000 ₽',
    hasFreespins: true,
  },
  {
    level: 16,
    points: 18000,
    cashback: 46,
    bonus: '30 000 ₽',
    wager: 0,
    withdrawLimit: '75 000 ₽',
    hasFreespins: true,
  },
  {
    level: 17,
    points: 24000,
    cashback: 48,
    bonus: '30 000 ₽',
    wager: 0,
    withdrawLimit: '75 000 ₽',
    hasFreespins: true,
  },
  {
    level: 18,
    points: 40000,
    cashback: 50,
    bonus: 'iPhone',
    wager: 0,
    withdrawLimit: '100 000 ₽',
    hasFreespins: true,
    personalManager: true,
  },
  {
    level: 19,
    points: 80000,
    cashback: 50,
    bonus: 'MacBook Pro',
    wager: 0,
    withdrawLimit: '300 000 ₽',
    hasFreespins: true,
    personalManager: true,
  },
  {
    level: 20,
    points: 160000,
    cashback: 50,
    bonus: '200 000 ₽',
    wager: 0,
    withdrawLimit: '500 000 ₽',
    hasFreespins: true,
    personalManager: true,
    premiumBonuses: true,
  },
  {
    level: 21,
    points: 400000,
    cashback: 50,
    bonus: '300 000 ₽',
    wager: 0,
    withdrawLimit: '1 000 000 ₽',
    hasFreespins: true,
    personalManager: true,
    premiumBonuses: true,
  },
  {
    level: 22,
    points: 1000000,
    cashback: 50,
    bonus: '1 000 000 ₽',
    wager: 0,
    withdrawLimit: '∞',
    hasFreespins: true,
    personalManager: true,
    premiumBonuses: true,
  },
]

const state = {
  levels: levelDescriptions,
}
const getters = {
  levels(state) {
    return state.levels
  },
  levelByIndex: state => idx => {
    return state.levels[idx]
  },
}

export default {
  state,
  getters,
}
