<template>
  <div
    :class="(slideperview === 7 ? 'slot-slider-right' : 'slot-slider-line') + ' slot-slider-' + category?.slug"
    v-if="games.length"
  >
    <div :class="'slot-slider-title game-mml game-mml-' + category?.slug">
      <span>{{ category.title }}</span>
      <router-link
        :to="{
          name: 'category',
          params: { category: category.slug },
        }"
        >Все
      </router-link>
    </div>

    <div class="slider-games">
      <swiper
        :slides-per-view="1"
        :modules="modules"
        :navigation="{ nextEl: `.slider-${category.slug}-next`, prevEl: `.slider-${category.slug}-prev` }"
        :breakpoints="{
          '370': {
            slidesPerView: 2,
          },
          '530': {
            slidesPerView: 3,
          },
          '600': {
            slidesPerView: this.slideperview - 5,
          },
          '710': {
            slidesPerView: this.slideperview - 4,
          },
          '890': {
            slidesPerView: this.slideperview - 3,
          },
          '1070': {
            slidesPerView: this.slideperview - 2,
          },
          '1250': {
            slidesPerView: this.slideperview - 1,
          },
          '1400': {
            slidesPerView: this.slideperview,
          },
        }"
      >
        <swiper-slide v-for="game in games" :key="game.id">
          <game-block :game="game" />
        </swiper-slide>
      </swiper>

      <div class="slider-games-nav">
        <div :class="`slider-games-prev slider-${category.slug}-prev slider-arrow`" />
        <div :class="`slider-games-next slider-${category.slug}-next slider-arrow slider-arrow-right`" />
      </div>
    </div>
  </div>
</template>

<script>
import GameBlock from '@/components/GameBlock'
import { useI18n } from 'vue-i18n'
import { Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'

export default {
  name: 'GameList',
  props: {
    manufacturer: {},
    category: {},
    games: Array,
    slideperview: {
      type: Number,
      default: 8,
    },
  },
  setup() {
    const { t } = useI18n()

    return { t, modules: [Navigation] }
  },
  components: {
    Swiper,
    SwiperSlide,
    GameBlock,
  },
}
</script>

<style scoped></style>
