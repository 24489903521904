<template>
  <table :class="`history-table history-table3 ${isRead ? 'read' : 'unread'} ${isMass ? 'mass' : ''}`">
    <tbody>
      <tr @click="toggleBody" :class="{ active }">
        <td @click="active = !active">{{ d(created_at) }}</td>
        <td @click="active = !active">
          <div class="tdhasbtn">
            <span class="tdhasbtn-text">{{ topic }}</span>
          </div>
        </td>
      </tr>
      <tr v-if="showBody">
        <td colspan="2" v-html="message"></td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { ref } from 'vue'

export default {
  name: 'Message',
  emits: ['read'],
  props: {
    id: {
      type: Number,
      required: true,
    },
    created_at: {
      type: String,
      required: true,
    },
    topic: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    isMass: {
      type: Boolean,
      required: true,
    },
    isRead: {
      type: Boolean,
      required: true,
    },
    onRead: {
      type: Function,
      required: false,
    },
  },
  setup(props, { emit }) {
    const { t, d } = useI18n()
    const showBody = ref(false)

    return { t, d, showBody }
  },
  methods: {
    toggleBody() {
      this.showBody = !this.showBody
      if (!this.isRead) {
        this.$emit('read', this.id)
      }
    },
  },
}
</script>

<style scoped></style>
