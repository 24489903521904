<template>
  <slider />

  <the-nav />

  <div class="main-wrap home-box">
    <game-list-search
      :games="this.gamesSearch(this.$route.params.string)"
      :string="this.$route.params.string"
      v-if="this.$route.params.string"
    />
    <template v-else>
      <template v-for="(category, index) in categories" :key="category.id">
        <template v-if="index === 0">
          <div
            class="slot-slider-cols"
            :style="this.gamesByCategory('day')?.length ? 'display: flex;' : 'display: block;'"
          >
            <div class="slot-slider-left" v-if="this.gamesByCategory('day')?.length">
              <div class="slot-title-day">
                <img src="/images/slot-title-day.svg" alt="Игра дня" />
                <span>Игра дня</span>
              </div>
              <div class="game-day">
                <game-block :game="this.gamesByCategory('day')[0]" />
              </div>
            </div>

            <game-list
              :games="this.gamesByCategory('top20')"
              :slideperview="this.gamesByCategory('day')?.length ? 7 : 8"
              :category="categories.find(elem => elem.slug === 'top20')"
              :manufacturer="this.manufacturerBySlug('top20')"
            />
          </div>
        </template>

        <template v-else-if="index === 3">
          <game-list
            :games="this.gamesByCategory(category.id)"
            :category="category"
            :manufacturer="this.manufacturerBySlug(category.slug)"
          />

          <home-middle-block />

          <vendors :list="manufacturers" />
        </template>

        <template v-else-if="index === 4">
          <game-list
            :games="this.gamesByCategory(category.id)"
            :category="category"
            :manufacturer="this.manufacturerBySlug(category.slug)"
          />

          <game-list
            :games="this.gamesByCategory('pragmatic')"
            :category="categories.find(elem => elem.slug === 'pragmatic')"
            :manufacturer="this.manufacturerBySlug('pragmatic')"
          />

          <winners-block />
        </template>

        <template v-else-if="index === 5">
          <game-list
            :games="this.gamesByCategory(category.id)"
            :category="category"
            :manufacturer="this.manufacturerBySlug(category.slug)"
          />
        </template>

        <template v-else-if="index === 6">
          <game-list
            :games="this.gamesByCategory(category.id)"
            :category="category"
            :manufacturer="this.manufacturerBySlug(category.slug)"
          />

          <game-list
            :games="this.gamesByCategory('pushgaming')"
            :category="categories.find(elem => elem.slug === 'pushgaming')"
            :manufacturer="this.manufacturerBySlug('pushgaming')"
          />

          <slot-topics />
        </template>

        <template v-else-if="index > 7 && index < 17">
          <game-list
            :games="this.gamesByCategory(category.id)"
            :category="category"
            :manufacturer="this.manufacturerBySlug(category.slug)"
          />
        </template>
      </template>
    </template>

    <jackpot-block />

    <bonuses-line />

    <play-line />

    <site-info />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Slider from '@/components/sliders/Slider'
import SlotTopics from '@/components/SlotTopics'
import PlayLine from '@/components/PlayLine'
import GameList from '@/components/GameList'
import TheNav from '@/components/TheNav'
import vendors from '@/components/Manufacturers'
import JackpotBlock from '@/components/JackpotBlock'
import HomeMiddleBlock from '@/components/HomeMiddleBlock'
import { useI18n } from 'vue-i18n'
import WinnersBlock from '@/components/Winners'
import BonusesLine from '@/components/BonusesLine.vue'
import SiteInfo from '@/components/SiteInfo'
import GameListSearch from '@/components/GameListSearch'
import GameBlock from '@/components/GameBlock.vue'

export default {
  name: 'home',
  components: {
    GameBlock,
    GameListSearch,
    BonusesLine,
    SiteInfo,
    JackpotBlock,
    WinnersBlock,
    HomeMiddleBlock,
    GameList,
    vendors,
    TheNav,
    PlayLine,
    SlotTopics,
    Slider,
  },
  setup() {
    const { t, locale } = useI18n()
    return { t, locale }
  },
  computed: {
    ...mapGetters([
      'isLoading',
      'games',
      'manufacturers',
      'gamesSearch',
      'gamesByCategory',
      'gamesByTopic',
      'gamesByManufacturer',
      'categories',
      'categoryBySlug',
      'manufacturerBySlug',
      'topicBySlug',
      'isAuthenticated',
    ]),
  },
}
</script>

<style>
@media screen and (min-width: 1000px) {
  .home-box .slot-slider-microgaming {
    display: none;
  }
}
</style>
