<template>
  <div class="head-line-r">
    <a href="#" class="open-chat" @click.prevent="support"></a>
    <router-link :to="{ name: 'register' }" class="head-reg-button">{{
      t('auth.register', 'Регистрация')
    }}</router-link>
    <router-link :to="{ name: 'login' }" class="head-login-button">{{ t('auth.log-in', 'Войти') }}</router-link>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { Talkme } from '@/components/talkme'

export default {
  name: 'TopGuest',
  setup() {
    const { t } = useI18n()
    return { t }
  },
  methods: {
    support() {
      Talkme.open()
    },
  },
}
</script>

<style scoped></style>
