<template>
  <a href="#" class="open-chat" @click.prevent="support"></a>
  <div class="head-line-r head-line-r-login hidemoblogin" v-if="currentUser?.username">
    <div class="login-user">
      <div class="login-user-image">
        <UserLevelImg :level="currentUser?.status.cur_level" />
      </div>

      <div class="login-user-content">
        <div class="login-user-title">
          <a @click="this.$router.push({ hash: '#profile' })">{{
            currentUser?.profile.display_name || currentUser?.username
          }}</a>

          <div :class="`login-user-block-ballance ${this.openDropdown ? 'active' : ''}`">
            <div class="login-user-ballance" @click="this.openDropdown = !this.openDropdown">
              {{ n(currentUser?.status.balance || 0, currentUser?.currency || 'RUB') }}
            </div>
            <a class="login-user-cassa" @click="this.$router.push({ hash: '#cashdesk' })">Касса</a>
            <a class="login-user-out" @click="this.logout">Выход</a>
          </div>
        </div>

        <span class="login-user-status" style="color: #81c105"
          >{{ ' ' }}{{ t('levels.' + currentUser?.status.cur_level) }}</span
        >
      </div>
    </div>

    <a href="#" class="open-chat" @click.prevent="support"></a>
    <a @click="this.$router.push({ hash: '#bonus' })" :class="`open-present ${bonusesCount > 0 ? 'active' : ''}`" />
    <a @click="this.$router.push({ hash: '#mails' })" :class="`open-mail ${mailCount > 0 ? 'active' : ''}`" />
    <a @click="this.$router.push({ hash: '#cashdesk' })" class="head-login-kassa">{{ t('cashdesk', 'Касса') }}</a>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { mapGetters } from 'vuex'
import { LOGOUT } from '@/store/actions.type'
import UserLevelImg from '@/components/UserLevelImg.vue'
import { Talkme } from '@/components/talkme'

export default {
  name: 'TopUser',
  components: { UserLevelImg },
  setup() {
    const { t, n } = useI18n()
    return { t, n }
  },
  computed: {
    ...mapGetters(['currentUser']),
    bonusesCount() {
      const fs = this.currentUser?.status?.freespins ? this.currentUser?.status?.freespins.length : 0
      const bonuses = this.currentUser?.status?.bonuses ? this.currentUser?.status?.bonuses.length : 0
      return fs + bonuses
    },
    mailCount() {
      return this.currentUser?.status?.unread_messages_count ? this.currentUser.status.unread_messages_count : 0
    },
  },
  data() {
    return {
      openDropdown: false,
    }
  },
  methods: {
    logout() {
      this.$store.dispatch(LOGOUT).then(() => {
        this.$router.push({ name: 'home' })
      })
    },
    support() {
      Talkme.open()
    },
  },
}
</script>

<style scoped></style>
