const ID_TOKEN_KEY = 'id_token'

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY)
}

export const parsedToken = () => {
  const token = getToken()
  if (!token) {
    return null
  }

  try {
    return JSON.parse(atob(token.split('.')[1]))
  } catch (e) {
    return null
  }
}

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token)
}

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY)
}

export default { getToken, saveToken, destroyToken }
